import {IOneForAll} from "../helpers/interfaces/IOneForAll";
import {IMember} from "../helpers/typesUtils";
import {AxiosResponse} from "axios";
import {fetchAction, getBaseURL, sliceObject} from "../helpers/utils";

class Member implements IOneForAll<IMember>,IMember{
    coyNumber: string='';
    emailAddress: string='';
    firstName: string='';
    lastName: string='';
    memberId: string='';
    otherNames: string='';
    phoneNumber: string='';
    sapNumber: string='';
    memberStatus:string='';

    async createInstance(): Promise<AxiosResponse<{status:number,operatedData:IMember}>> {
        const requestBody=this.getInstance();
        return await fetchAction('post', `${getBaseURL()}/membership/new_member`, {requestBody, memberId: requestBody.memberId});
    }

    async deleteInstance(deletingMemberId:string): Promise<AxiosResponse<{status:number,operatedData:IMember}>>  {
        return await fetchAction('post', `${getBaseURL()}/membership/delete_member`, {deletingMemberId});
    }

    getInstance(): Partial<IMember> {
        const props:Array<keyof IMember>=["memberId","firstName","lastName","sapNumber",
            "coyNumber","otherNames","emailAddress","phoneNumber","memberStatus"];
        return sliceObject(props,this);
    }

    makeInstance(stateValues: IMember): void {
        const {memberId,firstName,lastName,otherNames,memberStatus,
            emailAddress,phoneNumber,sapNumber,coyNumber}=stateValues;
        this.memberId=memberId;
        this.firstName=firstName;
        this.lastName=lastName;
        this.otherNames=otherNames;
        this.emailAddress=emailAddress;
        this.phoneNumber=phoneNumber;
        this.sapNumber=sapNumber;
        this.coyNumber=coyNumber;
        this.memberStatus=memberStatus
    }

    async updateInstance(): Promise<AxiosResponse<{status:number,operatedData:IMember}>> {
        const requestBody=this.getInstance();
        console.log(requestBody);
        return await fetchAction('post', `${getBaseURL()}/membership/update_member`, {requestBody});
    }
    async uploadUnionMembers(membersList:IMember[]): Promise<AxiosResponse<{status:number,operatedData:IMember[]}>> {
        return await fetchAction('post', `${getBaseURL()}/membership/upload_members`, {requestBody:membersList});
    }
}

export default Member;
