import {DatePicker, GeneralPageProps, IconTextInput, Loader, promptUserAction, SimpleTableWithMenu, tableEditOption} from "../helpers/components";
import React, {useEffect, useReducer, useRef} from "react";
import {Toast} from "primereact/toast";
import {IInvestmentInstruments, ILoans, TGeneralPage} from "../helpers/typesUtils";
import {customReducer, displayMessage, getBaseURL, getTableRowId, inputChange, pageDataValidation, REDUCER_ACTION_TYPE, sliceObject} from "../helpers/utils";
import {v4 as uuidv4} from "uuid";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {SelectButton} from "primereact/selectbutton";
import {Button} from "primereact/button";
import Investments from "../classes/Investments";
import {addRecordToCache, deleteCacheRecord, updateCacheRecord, useInvestmentInstruments} from "../helpers/reactQuery";
import {useQueryClient} from "@tanstack/react-query";
import {format, getYear} from "date-fns";
import Joi from "joi";

type TInvestmentInstruments = IInvestmentInstruments & TGeneralPage<IInvestmentInstruments> & { showInvestmentDialog: boolean }

const INITIAL_STATE: TInvestmentInstruments = {
    editingObjectId: "",
    editingState: false,
    isLoading: false,
    showDialog: false,
    expectedInterest: "",
    institution: "",
    investmentBegins: new Date(),
    investmentEnds: new Date(),
    investmentInstrumentId: "",
    investmentState: 'Rolling',
    showInvestmentDialog: false,
    amountInvested: '0'
}

const investments=new Investments();

const investValidation:Joi.ObjectSchema<IInvestmentInstruments>=Joi.object({
    investmentInstrumentId:Joi.string().required().messages({'string.empty':'Instrument ID was not found. Try again'}),
    institution:Joi.string().messages({'string.empty':'Input a valid name for saving institution0'}),
    amountInvested:Joi.number().min(1).messages({'number.min':'Amount invested should be greater than 0',
        'number.base':'Input a valid figure for amount investing'}),
    investmentBegins:Joi.date(),
    investmentEnds:Joi.date(),
    expectedInterest:Joi.number().min(1).messages({'number.min':'Expected interest is expected',
        'number.base':'Input a valid figure for expected interest!'}),
    investmentState:Joi.string()
});
const InvestmentInstruments = () => {
    const queryClient=useQueryClient();
    const toastRef = useRef<Toast>(null);

    const [state, dispatch] = useReducer(customReducer<TInvestmentInstruments>, INITIAL_STATE);

    const {data: investmentsList, isLoading, refetch,dataUpdatedAt} = useInvestmentInstruments({urlLink: `${getBaseURL()}/investment_instruments/get_instruments_list`});

    const setStateValues = (stateValues: Partial<TInvestmentInstruments>) => {
        dispatch({
            type: REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES,
            payload: {...stateValues}
        });
    }
    if(isLoading) return <Loader/>;

    const getStateValues=()=>{
        const props:Array<keyof TInvestmentInstruments>=["investmentInstrumentId",
            "institution","investmentBegins","investmentEnds","expectedInterest",
            "investmentState","amountInvested"]
        return sliceObject(props,state);
    }
    const investmentsMenu = () => {
        return [
            {
                label: 'New Instrument',
                icon: 'pi pi-plus',
                command: () => setStateValues({showInvestmentDialog: true})
            },
            {
                label: 'Refresh Table',
                icon: 'pi pi-refresh',
                command: () => refetch()
            },
        ]
    }
    const controlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        inputChange(e, dispatch);
    }
    const validateInvestmentPage=()=>{
        return pageDataValidation<IInvestmentInstruments>(investValidation,getStateValues(),toastRef);
    }
    const promptInvestmentSave = (event: React.MouseEvent<HTMLButtonElement>) => {
        if(!validateInvestmentPage() || !validateInvestmentDate()) return;
        promptUserAction({yesAction: saveInvestmentInstrument, event, displayText: 'Are you sure you want to add this investment?'});
    }
    const promptInvestmentUpdate = (event: React.MouseEvent<HTMLButtonElement>) => {
        if(!validateInvestmentPage() || !validateInvestmentDate()) return;
        promptUserAction({yesAction: updateInvestmentInstrument, event, displayText: 'Are you sure you want to update this investment?'});
    }
    const saveInvestmentInstrument=async ()=>{
        try{
            investments.makeInstance(getStateValues() as IInvestmentInstruments);
            setStateValues({isLoading:true});
            const newInvestmentResponse=await investments.createInstance();

            if(newInvestmentResponse.data.status===1){
                await addRecordToCache(queryClient, ['investmentInstruments'], newInvestmentResponse.data.operatedData);
                displayMessage({
                    header: 'Application Success',
                    message: 'Loan application was successfully created!',
                    infoType: 'success',
                    toastComponent: toastRef,
                    life: 5000
                });
                setStateValues({showDialog: false})
            }
        }catch(error:any){
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }finally {
            resetStateValues();
        }
    }
    const updateInvestmentInstrument=async ()=>{

        try{
            investments.makeInstance(getStateValues() as IInvestmentInstruments);
            setStateValues({isLoading:true});
            const updatedInvestmentResponse=await investments.updateInstance();
            if(updatedInvestmentResponse.data.status===1){
                await updateCacheRecord(queryClient, ['investmentInstruments'],
                    [updatedInvestmentResponse.data.operatedData, state.editingObjectId, 'investmentInstrumentId']);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Instrument Edit Success',
                    message: 'Selected instrument was successfully updated!',
                    infoType: 'success',
                    life: 3000
                });
            }
        }catch(error:any){
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }finally {
            resetStateValues();
        }
    }
    const onInvestmentDialogShow=()=>{
        if(!state.editingState){
            setStateValues({
                investmentInstrumentId: uuidv4()
            });
        }
    }
    const setupInvestmentEdit=(event:React.MouseEvent<HTMLButtonElement>)=>{
        const clickedInstrumentId=getTableRowId(event,'id');
        const selectedInstrument=investmentsList?.find((instrument:IInvestmentInstruments)=>instrument.investmentInstrumentId===clickedInstrumentId);
        if(selectedInstrument){
            const {investmentInstrumentId,institution,investmentBegins,
                investmentEnds,investmentState,amountInvested,
                expectedInterest}=selectedInstrument;
            setStateValues({
               investmentInstrumentId,
                institution,
                investmentBegins:new Date(investmentBegins as Date),
                investmentEnds:new Date(investmentEnds as Date),
                investmentState,
                amountInvested,
                expectedInterest,
                editingState:true,
                editingObjectId:investmentInstrumentId,
                showInvestmentDialog:true,
            });
        }
    }
    const deleteInvestmentInstrument=async (event:React.MouseEvent<HTMLButtonElement>)=>{
        try{
            const deletingInvestmentId=getTableRowId(event,'name');
            setStateValues({isLoading:true});
            const deletedInvestmentResponse=await investments.deleteInstance(deletingInvestmentId);
            if(deletedInvestmentResponse.data.status===1){
                await deleteCacheRecord<IInvestmentInstruments>(queryClient, ['investmentInstruments'],
                    [deletedInvestmentResponse.data.operatedData, deletingInvestmentId, 'investmentInstrumentId']);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Delete Success',
                    message: 'Investment Instrument was successfully removed!',
                    infoType: 'success',
                    life: 5000
                });
            }
        }catch(error:any){
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }finally {
            setStateValues({isLoading:false});
        }
    }
    const resetStateValues=()=>{
        setStateValues({
            isLoading:false,
            editingState:false,
            showDialog:false,
            expectedInterest:'',
            institution:'',
            investmentState:'Rolling',
            investmentBegins:new Date(),
            investmentInstrumentId: "",
            showInvestmentDialog: false,
            amountInvested: '0'
        });
    }
    const validateInvestmentDate=()=>{
        if(getYear(state.investmentBegins as Date) !==getYear(state.investmentEnds as Date)){
            displayMessage({
                header:'Error',
                message:'Investment dates should be of the year',
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
            return false;
        }
        return true;
    }
    return (
        <>
            {state.isLoading && <Loader/>}
            <GeneralPageProps toastRef={toastRef}/>
            <div className="p-fluid lg:pl-5">
                <SimpleTableWithMenu
                    tableKey={"investmentInstrumentId"}
                    columnsDef={
                        [
                            {field: 'institution', header: 'Institution'},
                            {body: (rowData:IInvestmentInstruments) => <div>{format(new Date(rowData.investmentBegins.toString()), 'yyyy-MM-dd')}</div>, header: 'From'},
                            {body: (rowData:IInvestmentInstruments) => <div>{format(new Date(rowData.investmentEnds.toString()), 'yyyy-MM-dd')}</div>, header: 'To'},
                            {field: 'amountInvested', header: 'Amount Invested'},
                            {field: 'expectedInterest', header: 'Expected Interest'},
                            {field: 'investmentState', header: 'Investment State'},
                            {body: (rowData: IInvestmentInstruments) => tableEditOption(setupInvestmentEdit, deleteInvestmentInstrument, rowData.investmentInstrumentId), header: 'Edit'},
                        ]
                    }
                    tableData={investmentsList}
                    menuModel={investmentsMenu()}
                    hasMenuList={true}
                    tableTitle="Investments Instruments"
                    lastTableUpdate={dataUpdatedAt}
                    searchValues={['institution']}
                    searchFieldPlaceHolder="Search by Institution"
                />
            </div>
            <Dialog onHide={() => setStateValues({showInvestmentDialog: false, editingState: false})} visible={state.showInvestmentDialog}
                    header="New Instrument"
                    position="top-right"
                onShow={onInvestmentDialogShow}
                    className="lg:w-7">
                <div className="p-fluid">
                    <div className="grid p-formgrid">
                        <IconTextInput value={state.institution} onInputChange={controlChange}
                                       placeholderValue="Institution/Bank"
                                       iconText="pi pi-building" componentId="institution"
                                       customClasses="lg:col-6 md:col-12 col-12"/>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="amountInvested">Amount Invested</label>
                            <InputText type="number"
                                       value={state.amountInvested}
                                       onChange={(e) => setStateValues({amountInvested: e.target.value})}
                                       id="amountInvested" min={0}
                            />
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="dueDate">Date Begins</label>
                            <DatePicker
                                dateValue={state.investmentBegins}
                                onDateChange={(e)=>setStateValues({investmentBegins:e.value!})}
                                labelText="Date Begins"
                                controlId="investmentBegins"
                            />
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="investmentEnds">Date Ends</label>
                            <DatePicker
                                dateValue={state.investmentEnds}
                                onDateChange={(e)=>setStateValues({investmentEnds:e.value!})}
                                labelText="Date Ends"
                                controlId="investmentEnds"
                            />
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="expectedInterest">Expected Earn-able</label>
                            <InputText type="number"
                                       value={state.expectedInterest}
                                       onChange={(e) => setStateValues({expectedInterest: e.target.value})}
                                       id="expectedInterest" min={0}
                            />
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="investmentState">Investment State</label>
                            <SelectButton value={state.investmentState} onChange={(e)=>setStateValues({investmentState:e.value})} options={['Rolling','Not Rolling']} />
                        </div>
                    </div>
                    <div className="field lg:col-6 md:col-12 col-12">
                        <Button onClick={!state.editingState?promptInvestmentSave:promptInvestmentUpdate}>{!state.editingState?'Save Instrument':'Update Instrument'}</Button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}
export default InvestmentInstruments;
