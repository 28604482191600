import {IMember, IWithdrawalRequest, TGeneralPage} from "../helpers/typesUtils";
import React, {useEffect, useReducer, useRef} from "react";
import {configureExcelUpload, customReducer, displayMessage, downloadExcelFile, DropdownOption, getBaseURL, getTableRowId, pageDataValidation, REDUCER_ACTION_TYPE, remakeDropdownSelects, selectControlChange, sliceObject} from "../helpers/utils";
import {Toast} from "primereact/toast";
import FileUploader, {DatePicker, FilterSelect, GeneralPageProps, Loader, promptUserAction, SimpleTableWithMenu, tableEditOption} from "../helpers/components";
import {format} from "date-fns";
import {Dialog} from "primereact/dialog";
import {DropdownChangeEvent} from "primereact/dropdown";
import {addRecordsToCache, addRecordToCache, deleteCacheRecord, updateCacheRecord, useMembershipListFetch, useWithdrawalsListFetch} from "../helpers/reactQuery";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
import WithdrawalRequest from "../classes/WithdrawalRequest";
import {useQueryClient} from "@tanstack/react-query";
import Joi from "joi";
import {v4 as uuidv4} from 'uuid';
import {FileUploadHandlerEvent} from "primereact/fileupload";
type TWithdrawal=IWithdrawalRequest & TGeneralPage<IWithdrawalRequest> & Partial<IMember> & {members:DropdownOption[],fullName?:string,showUploaderDialog:boolean};
const INITIAL_STATE:TWithdrawal={
    editingObjectId: "",
    editingState: false,
    isLoading: false,
    members: [],
    showDialog: false,
    amountWithdrawing: '0',
    memberId: "",
    purpose: "",
    requestDate: new Date(),
    withdrawalBalance: 0,
    withdrawalId: "",
    showUploaderDialog:false

}

const withdrawal=new WithdrawalRequest();

const validateRequest:Joi.ObjectSchema<IWithdrawalRequest>=Joi.object({
    withdrawalId:Joi.string().required().messages({'any.required':'Withdrawal ID is missing'}),
    memberId:Joi.string().required().messages({'string.empty':'Select a valid member before proceeding with request'}),
    purpose:Joi.string().allow(''),
    requestDate:Joi.date().required().messages({'any.required':'Please add a valid date for request'}),
    amountWithdrawing:Joi.number().required().min(1).messages({'number.min':'Withdrawal amount should exceed 0'}),
});
const Withdrawal=()=>{
    const queryClient=useQueryClient();

    const [state, dispatch] = useReducer(customReducer<TWithdrawal>, INITIAL_STATE);

    const toastRef=useRef<Toast>(null);

    const {data:withdrawalsList, isLoading:withdrawalsLoading, refetch:fetchWithdrawals,dataUpdatedAt} = useWithdrawalsListFetch({urlLink: `${getBaseURL()}/withdrawals/get_all_withdrawals`});

    const {data:membersList, isLoading, refetch} = useMembershipListFetch({urlLink: `${getBaseURL()}/membership/get_all_members`});

    const uploadComponentRef = useRef(null);

    useEffect(() => {
        if(membersList){
            setStateValues({
                members:remakeDropdownSelects(membersList,'fullName','memberId'),
            });
        }
    }, []);
    const setStateValues = (stateValues: Partial<TWithdrawal>) => {
        dispatch({
            type: REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES,
            payload: {...stateValues}
        });
    }
    if(withdrawalsLoading) return <Loader/>;
    if(isLoading) return <Loader/>;

    const getStateValues=()=>{
        const props:Array<keyof IWithdrawalRequest>=["withdrawalId","memberId","purpose","requestDate","amountWithdrawing"];
        return sliceObject(props,state);
    }
    const validateWithdrawal=():boolean=>{
        return pageDataValidation<IWithdrawalRequest>(validateRequest,getStateValues(),toastRef);
    }
    const withdrawalMenu=()=>{
        return [
            {
                label: 'New Request',
                icon: 'pi pi-plus',
                command: () => setStateValues({showDialog: true})
            },
            {
                label: 'Upload Requests',
                icon: 'pi pi-upload',
                command: () => setStateValues({showUploaderDialog: true})
            },
            {
                label: 'Requests List',
                icon: 'pi pi-download',
                command: () => downloadWithdrawalRequests()
            },
            {
                label: 'Download File',
                icon: 'pi pi-download',
                command: () => downloadUploadFile()
            },
            {
                label: 'Refresh',
                icon: 'pi pi-refresh',
                command: () => fetchWithdrawals()
            },
        ]
    }

    const onSelectChange = (e: DropdownChangeEvent) => {
        selectControlChange(e,dispatch);
    }
    const promptWithdrawalRequest=(event: React.MouseEvent<HTMLButtonElement>)=>{
        if(!validateWithdrawal()) return;
        promptUserAction({yesAction: confirmRequest, event, displayText: 'Are you sure you want to make this withdrawal request?'});

    }
    const promptWithdrawalUpdate=(event: React.MouseEvent<HTMLButtonElement>)=>{
        if(!validateWithdrawal()) return;
        promptUserAction({yesAction: updateRequest, event, displayText: 'Are you sure you want to update this withdrawal request?'});
    }
    const promptWithdrawalDelete=(event: React.MouseEvent<HTMLButtonElement>)=>{
        promptUserAction({yesAction: ()=>deleteWithdrawal(event), event, displayText: 'Are you sure you want to update this withdrawal request?'});
    }

    const deleteWithdrawal=async (event: React.MouseEvent<HTMLButtonElement>)=>{
        try{
            const deletingWithdrawalId=getTableRowId(event,'name');
            setStateValues({isLoading:true});
            const deleteWithdrawalResponse=await withdrawal.deleteInstance(deletingWithdrawalId);
            if(deleteWithdrawalResponse.data.status===1){
                await deleteCacheRecord<IWithdrawalRequest>(queryClient,['withdrawalsList'],
                    [deleteWithdrawalResponse.data.operatedData,deletingWithdrawalId,'withdrawalId']);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Delete Success',
                    message: 'Request was successfully deleted!',
                    infoType: 'success',
                    life: 5000
                });
            }
        }catch(error:any){
            throw Error(error.message);
        }finally {
            setStateValues({isLoading:false});
        }
    }
    const confirmRequest=async ()=>{
        try{
            withdrawal.makeInstance(getStateValues() as IWithdrawalRequest);
            setStateValues({isLoading:true});
            const requestResponse=await withdrawal.createInstance();
            if(requestResponse.data.status===1){
                await addRecordToCache(queryClient,['withdrawalsList'],requestResponse.data.operatedData);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Save Success',
                    message: 'New Request was successfully made with the supplied credentials',
                    infoType: 'success',
                    life: 5000
                });
            }
        }catch(error:any){
            throw Error(error.message);
        }finally {
            resetStateValues();
        }
    }
    const updateRequest=async ()=>{
        try{
            withdrawal.makeInstance(getStateValues() as IWithdrawalRequest);
            setStateValues({isLoading:true});
            const updateResponse=await withdrawal.updateInstance();
            if(updateResponse.data.status===1){

                await updateCacheRecord(queryClient,['withdrawalsList'],
                    [updateResponse.data.operatedData,state.editingObjectId,'withdrawalId']);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Update Success',
                    message: 'Selected Withdrawal request was successfully updated with the supplied credentials',
                    infoType: 'success',
                    life: 3000
                });
            }
        }catch(error:any){
            throw Error(error.message);
        }finally {
            resetStateValues();
        }
    }
    const onRequestDialogShown=()=>{
        if(!state.editingState){
            setStateValues({
                withdrawalId:uuidv4()
            });
        }
    }
    const setupWithdrawalEdit=(event: React.MouseEvent<HTMLButtonElement>)=>{
        const clickedRequestId=getTableRowId(event,'id');
        const selectedRequest=withdrawalsList?.find((withdrawal:IWithdrawalRequest)=>withdrawal.withdrawalId===clickedRequestId);
        const {withdrawalId,memberId,purpose,requestDate,amountWithdrawing}=selectedRequest!;
        setStateValues({
           withdrawalId,
           memberId,
           purpose,
           requestDate:new Date(requestDate.toString()),
           amountWithdrawing,
           editingState:true,
           editingObjectId:clickedRequestId,
           showDialog:true
        });
    }
    const downloadWithdrawalRequests=()=>{
        try{
            const requestsList=withdrawalsList?.map((withdrawalRequest:Partial<TWithdrawal>)=>{
                return {
                    sapNumber:withdrawalRequest.sapNumber,
                    coyNumber:withdrawalRequest.coyNumber,
                    fullName:withdrawalRequest.fullName,
                    requestDate:format(new Date(withdrawalRequest.requestDate!.toString()),'yyyy-MM-dd'),
                    purpose:withdrawalRequest.purpose,
                    amountWithdrawn:withdrawalRequest.amountWithdrawing,
                }
            });
            downloadExcelFile(requestsList,'withdrawal_requests_list',[
                {
                    sapNumber:'SAP NUMBER',
                    coyNumber:'COY NUMBER',
                    fullName:'FULL NAME',
                    requestDate:'REQUEST DATE',
                    purpose:'PURPOSE',
                    amountWithdrawn:'WITHDRAWING AMOUNT'
                }
            ])
        }catch(error:any){
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }
    }
    const downloadUploadFile=()=>{
        try{
            const requestsList=membersList?.map((withdrawalRequest:Partial<TWithdrawal>)=>{
                return {
                    memberId:withdrawalRequest.memberId,
                    sapNumber:withdrawalRequest.sapNumber,
                    coyNumber:withdrawalRequest.coyNumber,
                    fullName:withdrawalRequest.fullName,
                    phoneNumber:withdrawalRequest.phoneNumber,
                    requestDate:format(new Date(),'yyyy-MM-dd'),
                    purpose:'',
                    amountWithdrawn:0.00,
                }
            });
            downloadExcelFile(requestsList,'requests_file',[
                {
                    memberId:'MEMBER ID',
                    sapNumber:'SAP NUMBER',
                    coyNumber:'COY NUMBER',
                    fullName:'FULL NAME',
                    phoneNumber:'PHONE NUMBER',
                    requestDate:'REQUEST DATE',
                    purpose:'PURPOSE',
                    amountWithdrawn:'WITHDRAWING AMOUNT'
                }
            ]);
        }catch(error:any){
            throw Error(error.message);
        }
    }
    const uploadWithdrawalRequests=async (event:FileUploadHandlerEvent)=>{
        const file=event.files[0];
        const uploadFileHeaders=['MEMBER ID','SAP NUMBER','COY NUMBER','FULL NAME','PHONE NUMBER','REQUEST DATE','PURPOSE','WITHDRAWING AMOUNT'];
        const uploadObjectNameFields=['memberId','sapNumber','coyNumber','fullName','phoneNumber','requestDate','purpose','withdrawalAmount'];
        const uploadData=await configureExcelUpload(file,uploadFileHeaders,uploadObjectNameFields);

        const uploadResponse=await withdrawal.uploadMemberRequests(uploadData);
        if(uploadResponse.data.status===1){
            await addRecordsToCache(queryClient,['withdrawalsList'],uploadResponse.data.operatedData);
            displayMessage({
                header:'Upload Success',
                message:'Withdrawals were successfully uploaded',
                infoType:'success',
                toastComponent:toastRef,
                life:5000
            });
            resetStateValues();
        }
    }
    const resetStateValues=()=>{
        setStateValues({
            memberId:'',
            amountWithdrawing:'',
            purpose:'',
            requestDate:new Date(),
            withdrawalId:uuidv4(),
            showDialog:false,
            isLoading:false,
            editingState:false
        });
    }
    return (
        <>
            {state.isLoading && <Loader/>}
            <GeneralPageProps toastRef={toastRef}/>
            <div className="p-fluid lg:pl-5">
                <SimpleTableWithMenu
                    tableKey={"withdrawalId"}
                    columnsDef={
                        [
                            {field: 'fullName', header: 'Name'},
                            {body: (rowData:IWithdrawalRequest)=><div>{format(new Date(rowData.requestDate.toString()),'yyyy-MM-dd')}</div>, header: 'Name'},
                            {field: 'amountWithdrawing', header: 'Amount'},
                            {body: (rowData: IWithdrawalRequest) => tableEditOption(setupWithdrawalEdit, promptWithdrawalDelete, rowData.withdrawalId), header: 'Edit'},
                        ]
                    }
                    tableData={withdrawalsList}
                    menuModel={withdrawalMenu()}
                    hasMenuList={true}
                    tableTitle="Withdrawal Request"
                    lastTableUpdate={dataUpdatedAt}
                    searchValues={['sapNumber','coyNumber','fullName']}
                    searchFieldPlaceHolder="Search by Full Name, COY Number, SAP Number"
                />
            </div>
            <Dialog onHide={() => setStateValues({showDialog: false, editingState: false})} visible={state.showDialog}
                    header="New Withdrawal Request"
                    position="top-right"
                    onShow={onRequestDialogShown}
                    className="lg:w-7">
                <div className="p-fluid">
                    <div className="grid p-formgrid">
                        <div className="field lg:col-6 md:col-12 col-12">
                            <FilterSelect
                                selectableOptions={state.members}
                                selectedOption={state.memberId}
                                onSelectChange={onSelectChange}
                                elementId="memberId"
                                defaultValue="Members List"/>
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="requestDate">Request Date</label>
                            <DatePicker
                                dateValue={state.requestDate}
                                onDateChange={(e) => setStateValues({requestDate: e.value!})}
                                labelText="Request Date"
                                controlId="requestDate"
                            />
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="amountWithdrawing">Request Amount</label>
                            <InputText type="number"
                                       value={state.amountWithdrawing}
                                       onChange={(e)=>setStateValues({amountWithdrawing:e.target.value!})}
                                       id="amountWithdrawing"
                            />
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="amountWithdrawing">Purpose</label>
                            <InputTextarea value={state.purpose} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setStateValues({purpose:e.target.value})} rows={5} cols={30} />
                        </div>
                        <div className="field lg:col-4 md:col-12 col-12">
                            <Button onClick={!state.editingState?promptWithdrawalRequest:promptWithdrawalUpdate}>{!state.editingState?'Confirm Request':'Update Request'}</Button>
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog onHide={()=>{setStateValues({showUploaderDialog:false})}} visible={state.showUploaderDialog} position="bottom-right">
                <FileUploader onFileUpload={uploadWithdrawalRequests} fileUploadRef={uploadComponentRef} id="membersUploader" acceptableFileTypes=".xlsx,.csv"/>
            </Dialog>
        </>
    )
}

export default Withdrawal;
