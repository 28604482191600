import {IOneForAll} from "../helpers/interfaces/IOneForAll";
import {ILoans, TScannedFiles} from "../helpers/typesUtils";
import {AxiosResponse} from "axios";
import {fetchAction, getBaseURL, sliceObject} from "../helpers/utils";
import {format} from "date-fns";

class Loans implements IOneForAll<ILoans>,ILoans{

    applicationDate: string | Date | Date[]=new Date();
    memberId: string='';
    monthlyDeductible: string='';
    principalAmount: string='';
    totalPayable: string='';
    interestRate: string='';
    loanApplicationId: string='';
    dueDate: string | Date | Date[]=new Date();
    allocatedMonths: string='';
    deductionStartDate: string | Date | Date[]=new Date();
    scannedFiles:TScannedFiles[]=[];
    scannedFileNames:string[]=[]

    async createInstance(): Promise<AxiosResponse<{status:number,operatedData:ILoans,error?:string}>> {
        const requestBody=this.getInstance();
        console.log(requestBody);
        return await fetchAction('post', `${getBaseURL()}/loans/new_loan_application`, {requestBody});
    }

    async deleteInstance(deletingLoanId: string):Promise<AxiosResponse<{status:number,operatedData:ILoans,error?:string}>> {
        return await fetchAction('post', `${getBaseURL()}/loans/delete_loan`, {deletingLoanId});
    }

    getInstance(): Partial<ILoans> {
        const props:Array<keyof ILoans>=["loanApplicationId","memberId","principalAmount","deductionStartDate",
            "interestRate","applicationDate","dueDate","monthlyDeductible","scannedFiles","scannedFileNames","deductionStartDate"];
        return sliceObject(props,this);
    }

    makeInstance(stateValues: ILoans): void {
        const {loanApplicationId,memberId,principalAmount,
            interestRate,applicationDate,dueDate,
            monthlyDeductible,scannedFiles,deductionStartDate,scannedFileNames}=stateValues;
        this.loanApplicationId=loanApplicationId;
        this.memberId=memberId;
        this.principalAmount=principalAmount;
        this.interestRate=interestRate;
        this.applicationDate=format(new Date(applicationDate.toString()),'yyyy-MM-dd');
        this.dueDate=format(new Date(dueDate.toString()),'yyyy-MM-dd');
        this.monthlyDeductible=monthlyDeductible;
        this.scannedFiles=scannedFiles;
        this.deductionStartDate=format(new Date(deductionStartDate as Date),'yyyy-MM-dd');
        this.scannedFileNames=scannedFileNames;
    }

    async updateInstance(): Promise<AxiosResponse<{status:number,operatedData:ILoans}>> {
        const requestBody=this.getInstance();
        return await fetchAction('post', `${getBaseURL()}/loans/update_loan_application`, {requestBody});
    }
    async acceptLoanPayment(loanData:ILoans): Promise<AxiosResponse<{status:number,operatedData:ILoans}>> {
        return await fetchAction('post', `${getBaseURL()}/loans/accept_loan_payment`, {requestBody:loanData});
    }
    async updateLoanPayment(loanData:ILoans): Promise<AxiosResponse<{status:number,operatedData:ILoans}>> {
        return await fetchAction('post', `${getBaseURL()}/loans/update_loan_payment`, {requestBody:loanData});
    }
}
export default Loans;
