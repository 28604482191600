import React, {useRef, Fragment} from "react";
import {Dropdown, DropdownChangeEvent} from "primereact/dropdown";
import {DropdownOption} from "./utils";
import {
    DatePickerProps, GeneralPagePropsProps, ISpinner, MenuModel,
    PasswordInputProps, PromptUserActionProps, SelectableOptions, SimpleTableWithMenuProps, TIconTextInput
} from "./typesUtils";
import {Divider} from "primereact/divider";
import {Password} from "primereact/password";
import {DataTable, DataTableFilterMeta} from 'primereact/datatable';
import {Menu} from 'primereact/menu';
import {Button} from 'primereact/button';
import {Column} from "primereact/column";
import {InputText} from "primereact/inputtext";
import {Calendar} from "primereact/calendar";
import {ConfirmPopup, confirmPopup} from "primereact/confirmpopup";
import {Toast} from "primereact/toast";
import {useState} from 'react';
import {
    FileUpload, FileUploadHandlerEvent, FileUploadHeaderTemplateOptions, FileUploadSelectEvent,
    FileUploadUploadEvent, ItemTemplateOptions,
} from 'primereact/fileupload';
import {ProgressBar} from 'primereact/progressbar';

import {Tooltip} from 'primereact/tooltip';
import {Tag} from 'primereact/tag';
import {FilterMatchMode} from "primereact/api";
import {ProgressSpinner} from "primereact/progressspinner";

interface FilterSelectProps {
    selectableOptions: DropdownOption[];
    selectedOption: number | string;
    onSelectChange: (e: DropdownChangeEvent) => void;
    customClasses?: string;
    elementId: string;
    defaultValue: string;
    showClearIcon?: boolean;
}

export const FilterSelect: React.FC<FilterSelectProps> = ({
                                                              selectableOptions = [],
                                                              selectedOption = "",
                                                              onSelectChange,
                                                              customClasses = "",
                                                              elementId = "",
                                                              defaultValue = "",
                                                              showClearIcon = false,
                                                          }) => {
    const selectedValueTemplate = (option: SelectableOptions) => {
        if (option) {
            return (
                <div className="item-value flex align-items-center">
                    <div>{option.name}</div>
                </div>
            );
        }
        return <span>Select</span>;
    };

    const optionsTemplate = (option: { name: string }) => {
        return (
            <div className="flex align-items-center">
                <div>{option.name}</div>
            </div>
        );
    };

    return (
        <div className={`filter-select ${customClasses}`}>
            <div className="mb-2"><label htmlFor="dateTo">{defaultValue}</label></div>
            <Dropdown
                value={selectedOption}
                options={selectableOptions}
                onChange={onSelectChange}
                optionLabel="name"
                filter
                filterBy="name"
                placeholder="Select"
                id={elementId}
                defaultValue={defaultValue}
                valueTemplate={selectedValueTemplate}
                itemTemplate={optionsTemplate}
                name={elementId}
                className="w-full"
                showClear={showClearIcon}
            />
        </div>
    );
};


export function PasswordInput({
                                  passwordValue = "",
                                  onPasswordChange,
                                  groupIcon = "",
                                  inputLabel = "",
                                  name = "",
                                  inputPlaceholder = 'Input Password'
                              }: PasswordInputProps) {
    const header = <h6>{inputLabel}</h6>;

    const footer = (
        <React.Fragment>
            <Divider/>
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0" style={{lineHeight: "1.5"}}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </React.Fragment>
    );

    return (
        <div className="col-12 pt-0">
            <div className="p-inputgroup">
        <span className="p-inputgroup-addon">
          <i className={groupIcon}></i>
        </span>
                <Password
                    className="expanded-control"
                    placeholder={inputPlaceholder}
                    value={passwordValue}
                    onChange={onPasswordChange}
                    header={header}
                    footer={footer}
                    toggleMask
                    name={name}
                    inputId={name}
                />
            </div>
        </div>
    );
}


export const SimpleTableWithMenu = ({
                                        tableKey,
                                        columnsDef,
                                        tableData,
                                        showTableLines = true,
                                        tableMinWidth = 80,
                                        menuModel,
                                        tableTitle = 'Table Data',
                                        hasMenuList = false,
                                        lastTableUpdate,
                                        searchValues = [],
                                        searchFieldPlaceHolder = ''
                                    }: SimpleTableWithMenuProps) => {

    const menuRef = useRef<Menu>(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState<DataTableFilterMeta>({
        global: {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const onClickToDisplayMenu = (e: React.MouseEvent) => {
        const menuElement = menuRef.current;
        if (menuElement && typeof menuElement.toggle === 'function') {
            menuElement.toggle(e);
        } else {
            console.error('toggle function is not available on the menu element.');
        }
    };
    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = {...filters};

        // @ts-ignore
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const MenuComponent = () => {
        return (
            <Fragment>
                <div className="flex align-items-center justify-content-between mb-2">
                    <h5>{tableTitle}</h5>
                    <Button
                        type="button"
                        icon="pi pi-ellipsis-v"
                        className="p-button-rounded p-button-text p-button-plain"
                        onClick={onClickToDisplayMenu}
                    />
                    <Menu ref={menuRef} popup model={menuModel}/>
                </div>
            </Fragment>
        );
    };
    const renderHeader = () => {
        return (
            <div className="flex flex-row-reverse flex-wrap">
                {/*<Button type="button" icon="pi pi-filter-slash" label="Clear" outlined onClick={()=>{}} />*/}
                <div className="flex align-items-center justify-content-center w-25rem font-bold">
                                    <span className="p-input-icon-left">
                    <i className="pi pi-search"/>
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder={searchFieldPlaceHolder} autoComplete="off"/>
                </span>
                </div>
            </div>
        );
    };
    return (
        <React.Fragment>
            {hasMenuList && <MenuComponent/>}
            <DataTable
                value={tableData}
                paginator
                rows={5}
                scrollable
                showGridlines={showTableLines}
                dataKey={tableKey}
                rowsPerPageOptions={[5, 10, 25, 50]}
                globalFilterFields={searchValues}
                filters={filters}
                header={renderHeader()}
                tableStyle={{minWidth: `${tableMinWidth}rem`}}
            >
                {columnsDef.length > 0 &&
                    columnsDef.map((columnDef, index) => (
                        <Column
                            key={index}
                            field={columnDef.field}
                            header={columnDef.header}
                            body={columnDef.body}
                        />
                    ))}
            </DataTable>
            <span className="smal">{`${new Date(lastTableUpdate!)}`}</span>
            {/*<Tag value={`${new Date(lastTableUpdate!)}`} severity="success" className="mt-3" icon="pi pi-refresh"/>*/}
        </React.Fragment>
    );
};
export const IconTextInput = ({value, onInputChange, placeholderValue, iconText, componentId, customClasses}: TIconTextInput) => {
    return (
        <>
            <div className={`expanded-control ${customClasses}`}>
                <div className="mb-2"><label htmlFor={componentId}>{placeholderValue}</label></div>
                <span className="p-input-icon-left">
                                <i className={`pi ${iconText}`}/>
                                <InputText type="text" id={componentId} value={value}
                                           placeholder={placeholderValue}
                                           onChange={onInputChange} autoComplete="off"/>
                            </span>
            </div>
        </>
    )
}

export const DatePicker: React.FC<DatePickerProps> = ({
                                                          dateValue,
                                                          onDateChange,
                                                          labelText,
                                                          controlId,
                                                          dateFormat = "yy-mm-dd",
                                                          displayTime = false,
                                                          selectionType = 'single',
                                                          displayButtonBar = false,
                                                          minimumDateValue,maximumDateValue,view='date'
                                                      }) => {
    return (
        <div className="">
            <Calendar
                name={controlId}
                id={controlId}
                placeholder={labelText}
                className="expanded-control mr-3"
                value={dateValue}
                onChange={onDateChange}
                dateFormat={dateFormat}
                selectionMode={selectionType}
                showButtonBar={displayButtonBar}
                showTime={displayTime}
                minDate={minimumDateValue}
                maxDate={maximumDateValue}
                view={view}
            />
        </div>
    );
};

export const promptUserAction = ({
                                     yesAction = () => {
                                     },
                                     noAction = () => {
                                     },
                                     event,
                                     displayText,
                                 }: PromptUserActionProps) => {
    confirmPopup({
        target: event.currentTarget,
        message: displayText,
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-success',
        rejectClassName: 'p-button-danger',
        acceptIcon: 'pi pi-check',
        rejectIcon: 'pi pi-times',
        accept: yesAction,
        reject: noAction,
        style: {width: '400px'},
    });
};
export const DefaultSpinner = ({customClasses = ''}: ISpinner) => {

    return (
        <><i className={`pi pi-spin pi-spinner ${customClasses}`}></i><span> ...Working on It. Please wait!</span></>
    )
}

export const tableEditOption = (editClick: (e: React.MouseEvent<HTMLButtonElement>) => void,
                                deleteClick: (e: React.MouseEvent<HTMLButtonElement>) => void,
                                idProperty = '', recordStatus?: 'online' | 'offline') => {
    return (
        <React.Fragment>
            <Button
                id={idProperty}
                icon="pi pi-pencil"
                className="p-button-rounded p-button-success mr-2"
                onClick={editClick}
            />
            <Button
                icon="pi pi-trash"
                name={idProperty}
                className="p-button-rounded p-button-warning"
                onClick={deleteClick}
            />
        </React.Fragment>
    );
};

export const GeneralPageProps: React.FC<GeneralPagePropsProps> = ({toastRef, toastPosition = 'bottom-right'}) => {
    return (
        <>
            <Toast ref={toastRef} position={toastPosition}/>
            <ConfirmPopup/>
        </>
    );
};


interface FileUploaderProps {
    onFileUpload: (event: FileUploadHandlerEvent) => void;
    fileUploadRef: React.RefObject<FileUpload>;
    acceptableFileTypes?: string;
    id: string;
    maximumFileSize?: number;
    acceptMultipleFiles?: boolean;
}

export default function FileUploader({onFileUpload, fileUploadRef, acceptableFileTypes = 'image/*', id, maximumFileSize = 1000000, acceptMultipleFiles = false}: FileUploaderProps) {
    const toast = useRef<Toast>(null);
    const [totalSize, setTotalSize] = useState(0);


    const onTemplateSelect = (e: FileUploadSelectEvent) => {
        let _totalSize = totalSize;
        let files = e.files;

        for (let i = 0; i < files.length; i++) {
            _totalSize += files[i].size || 0;
        }

        setTotalSize(_totalSize);
    };

    const onTemplateUpload = (e: FileUploadUploadEvent) => {
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize += file.size || 0;
        });

        setTotalSize(_totalSize);
        toast.current?.show({severity: 'info', summary: 'Success', detail: 'File Uploaded'});
    };

    const onTemplateRemove = (file: File, callback: Function) => {
        setTotalSize(totalSize - file.size);
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
    };

    const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
        const {className, chooseButton, uploadButton, cancelButton} = options;
        const value = totalSize / 10000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <div className="flex align-items-center gap-3 ml-auto">
                    <span>{formatedValue} / 1 MB</span>
                    <ProgressBar value={value} showValue={false} style={{width: '10rem', height: '12px'}}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (inFile: object, props: ItemTemplateOptions) => {
        const file = inFile as File;
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{width: '40%'}}>
                    <i className="pi pi-file-excel"></i>
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2"/>
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)}/>
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}></i>
                <span style={{fontSize: '1.2em', color: 'var(--text-color-secondary)'}} className="my-5">
                    Drag and Drop File Here
                </span>
            </div>
        );
    };

    const chooseOptions = {icon: 'pi pi-fw pi-file', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined'};
    const uploadOptions = {icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined'};
    const cancelOptions = {icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'};

    return (
        <div>
            <Toast ref={toast}></Toast>

            <Tooltip target=".custom-choose-btn" content="Choose" position="bottom"/>
            <Tooltip target=".custom-upload-btn" content="Upload" position="bottom"/>
            <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom"/>

            <FileUpload ref={fileUploadRef} name={id} uploadHandler={onFileUpload} customUpload={true} accept={acceptableFileTypes} maxFileSize={maximumFileSize}
                        onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                        headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate} multiple={acceptMultipleFiles}
                        chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions}/>
        </div>
    )
}

export const Loader=()=>{

    return(
        <div className="loader-container">
            <ProgressSpinner
                style={{width: '100px', height: '100px'}}
                strokeWidth="4"
                fill="var(--surface-ground)"
                animationDuration="1.5s"
            />
        </div>
    )
}
