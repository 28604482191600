import {IOneForAll} from "../helpers/interfaces/IOneForAll";
import {IContributions, IMember} from "../helpers/typesUtils";
import {AxiosResponse} from "axios";
import {fetchAction, getBaseURL, sliceObject} from "../helpers/utils";
import {format} from 'date-fns';

class ContributionsClass implements IOneForAll<IContributions>,IContributions{
    amountContributed: string='';
    contributionDate: Date | string | Date[]=new Date();
    contributionId: string='';
    memberId: string='';
    wageTypeId: number=0;
    async createInstance(): Promise<AxiosResponse<{status:number, operatedData:IContributions,error?:string}>> {
        const requestBody=this.getInstance();
        return await fetchAction('post', `${getBaseURL()}/contributions/new_contribution`, {requestBody});
    }

    async deleteInstance(deletingContributionId: string):Promise<AxiosResponse<{status:number,operatedData:IContributions}>> {
        return await fetchAction('post', `${getBaseURL()}/membership/delete_member`, {deletingContributionId});
    }

    getInstance(): Partial<IContributions> {
        const props:Array<keyof IContributions>=["contributionId","memberId","wageTypeId","amountContributed","contributionDate"];
        return sliceObject(props,this);
    }

    makeInstance(stateValues: IContributions): void {
        const {contributionId,memberId,wageTypeId,amountContributed,
            contributionDate}=stateValues;

        this.contributionId=contributionId;
        this.memberId=memberId;
        this.wageTypeId=wageTypeId;
        this.amountContributed=amountContributed;
        this.contributionDate=format(new Date(contributionDate.toString()),'yyyy-MM-dd');

    }

    async updateInstance(): Promise<AxiosResponse<{status:number,operatedData:IContributions}>> {
        const requestBody=this.getInstance();
        console.log(requestBody);
        return await fetchAction('post', `${getBaseURL()}/contributions/update_contribution`, {requestBody});
    }

    async uploadMemberContributions(contributionsList:IContributions[],contributionDate:string):Promise<AxiosResponse<{status:number,operatedData:IContributions[]}>>{
        return await fetchAction('post', `${getBaseURL()}/contributions/upload_contributions`,
            {requestBody:contributionsList,contributionDate});
    }
}

export default ContributionsClass;
