import {IMember, TGeneralPage} from "../helpers/typesUtils";
import React, {useEffect, useReducer, useRef} from "react";
import {useQueryClient} from "@tanstack/react-query";
import {configureExcelUpload, customReducer, displayMessage, downloadExcelFile, getBaseURL, getFileExtension, getTableRowId, inputChange, pageDataValidation, REDUCER_ACTION_TYPE, sliceObject, updateCacheItem} from "../helpers/utils";
import FileUploader, {DefaultSpinner, GeneralPageProps, IconTextInput, Loader, promptUserAction, SimpleTableWithMenu, tableEditOption} from "../helpers/components";
import {Dialog} from "primereact/dialog";
import {v4 as uuidv4} from 'uuid';
import {Button} from "primereact/button";
import Member from "../classes/Member";
import {addRecordsToCache, addRecordToCache, deleteCacheRecord, updateCacheRecord, useMembershipListFetch} from "../helpers/reactQuery";
import Joi from "joi";
import {FileUploadHandlerEvent} from "primereact/fileupload";
import {Toast} from "primereact/toast";
import {SelectButton} from "primereact/selectbutton";
import {Simulate} from "react-dom/test-utils";
import reset = Simulate.reset;
import {format} from "date-fns";

type TMembership = IMember & TGeneralPage<IMember> &{showUploaderDialog:boolean};
const INITIAL_STATE: TMembership = {
    editingObjectId: "",
    editingState: false,
    isLoading: false,
    showDialog: false,
    coyNumber: "",
    emailAddress: "",
    firstName: "",
    lastName: "",
    memberId: "",
    otherNames: "",
    phoneNumber: "",
    sapNumber: "",
    showUploaderDialog:false,
    memberStatus:'Active'
}

const member = new Member();

const memberValidation: Joi.ObjectSchema<IMember> = Joi.object({
    memberId: Joi.string().required(),
    sapNumber: Joi.string().required().messages({'string.empty': 'Input a valid SAP number', 'any.required': 'Add a valid SAP number'}),
    coyNumber: Joi.string().required().messages({'string.empty': 'Input a valid COY Number', 'any.required': 'Add a valid COY Number'}),
    firstName: Joi.string().required().messages({'string.empty': 'Input a valid first name', 'any.required': 'Add a valid first name'}),
    lastName: Joi.string().required().messages({'string.empty': 'Add a valid last name for member', 'any.required': 'Add a valid last name'}),
    otherNames: Joi.string().allow(''),
    memberName: Joi.string().allow(''),
    emailAddress: Joi.string().allow(''),
    phoneNumber: Joi.string().required().messages({'string.empty': 'Add a valid phone number', 'any.required': 'Add valid phone number'}),
    memberStatus:Joi.string().required().messages({"string.empty":'Select Member status type'})

});
const Membership = () => {
    const queryClient = useQueryClient();

    const [state, dispatch] = useReducer(customReducer<TMembership>, INITIAL_STATE);

    const toastRef = useRef<Toast>(null);

    const {data, isLoading, refetch, dataUpdatedAt} = useMembershipListFetch({urlLink: `${getBaseURL()}/membership/get_all_members`});

    const uploadComponentRef = useRef(null);

    useEffect(() => {
        setStateValues({memberId: uuidv4()});
    }, []);
    const setStateValues = (stateValues: Partial<TMembership>) => {
        dispatch({
            type: REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES,
            payload: {...stateValues}
        });
    }
    if (isLoading) {
        return <Loader/>
    }
    const getStateValues = () => {

        const props: Array<keyof TMembership> = ["memberId", "firstName", "lastName", "sapNumber",
            "coyNumber", "otherNames", "emailAddress", "phoneNumber","memberStatus"];

        return sliceObject(props, state);
    }
    const validateMember = (): boolean => {
        return pageDataValidation<IMember>(memberValidation, getStateValues(), toastRef);
    }
    const membershipMenu = () => {
        return [
            {
                label: 'Add New Member',
                icon: 'pi pi-plus',
                command: () => setStateValues({showDialog: true})
            },
            {
                label: 'Upload Members',
                icon: 'pi pi-upload',
                command: () => setStateValues({showUploaderDialog:true})
            },
            {
                label: 'Download Members',
                icon: 'pi pi-download',
                command: () => downloadMembersList()
            },
            {
                label: 'Upload File',
                icon: 'pi pi-download',
                command: () => downloadMembersUploadFile()
            },
            {
                label: 'Refresh Table ',
                icon: 'pi pi-refresh',
                command: () => refetch()
            }
        ]
    }
    const controlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        inputChange(e, dispatch);
    }
    const saveMember = async () => {
        try{
            setStateValues({isLoading:true});
            member.makeInstance(getStateValues() as IMember);
            setStateValues({isLoading:true});
            const memberResponse = await member.createInstance();
            if(memberResponse.data.status===1){
                await addRecordToCache(queryClient,['membersList'],memberResponse.data.operatedData);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Save Success',
                    message: 'New Member was successfully saved with the supplied credentials',
                    infoType: 'success',
                    life: 3000
                });
                resetStateValues();
            }
        }catch(error:any){
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }finally {
            setStateValues({isLoading:false});
        }
    }
    const setupMemberEdit = (e: React.MouseEvent<HTMLButtonElement>) => {

        const clickedMemberId = getTableRowId(e, 'id');

        const selectedMember = data?.find((member: IMember) => member.memberId === clickedMemberId);


        const {
            memberId, firstName, lastName, otherNames, sapNumber,
            coyNumber, emailAddress, phoneNumber,memberStatus
        } = selectedMember!;
        setStateValues({
            memberId,
            firstName,
            lastName,
            otherNames,
            emailAddress,
            sapNumber,
            coyNumber,
            phoneNumber,
            memberStatus,
            editingState: true,
            editingObjectId: clickedMemberId,
            showDialog: true
        });
    }
    const promptMemberDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
        promptUserAction({yesAction: () => doMemberDelete(event), event, displayText: 'Are you sure you want to delete selected Member data?'});
    }
    const promptMemberUpdate = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!validateMember()) return;
        promptUserAction({yesAction: updateMember, event, displayText: 'Are you sure you want to update selected Member data?'});
    }
    const promptMemberSave = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!validateMember()) return;
        promptUserAction({yesAction: saveMember, event, displayText: 'Are you sure you want to save this info to database?'});
    }
    const updateMember = async () => {
        try {
            setStateValues({isLoading:true});
            member.makeInstance(getStateValues() as IMember);
            setStateValues({isLoading:true});
            const updateResponse = await member.updateInstance();

            if (updateResponse.data.status === 1) {

                await updateCacheRecord<IMember>(queryClient, ['membersList'],
                    [updateResponse.data.operatedData, state.editingObjectId, 'memberId']);

                displayMessage({
                    toastComponent: toastRef,
                    header: 'Update Success',
                    message: 'Selected Member was successfully updated with the supplied credentials',
                    infoType: 'success',
                    life: 3000
                });
                resetStateValues()
            }
        } catch (error:any) {
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }finally {
            setStateValues({isLoading:false});
        }
    }
    const resetStateValues=()=>{
        setStateValues({
            sapNumber:'',
            coyNumber:'',
            firstName:'',
            lastName:'',
            otherNames:'',
            emailAddress:'',
            phoneNumber:'',
            memberStatus:'Active',
            showDialog:false,
            editingState:false,
            isLoading:false
        });
    }
    const doMemberDelete = async (event: React.MouseEvent<HTMLButtonElement>) => {
        try {
            setStateValues({isLoading:true});
            const deletingMemberId = getTableRowId(event, 'name');
            const removedItemResponse = await member.deleteInstance(deletingMemberId);
            if (removedItemResponse.data.status === 1) {
                await deleteCacheRecord<IMember>(queryClient, ['membersList'], [removedItemResponse.data.operatedData, deletingMemberId, 'memberId']);
                resetStateValues();
            }
        } catch (error: any) {
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }
    }
    const onMemberDialogShown = () => {
        if (!state.editingState) {
            setStateValues({
                memberId: uuidv4()
            });
        }
    }
    const onUploadFile=async (event: FileUploadHandlerEvent)=>{
        try{
            const file=event.files[0];
            const uploadFileHeaders=['SAP NUMBER', 'COY NUMBER', 'FIRST NAME', 'LAST NAME',
                'OTHER NAMES','EMAIL ADDRESS','PHONE NUMBER'];
            const uploadObjectNameFields=['sapNumber', 'coyNumber', 'firstName',
                'lastName', 'otherNames','emailAddress','phoneNumber'];
            const uploadData=await configureExcelUpload(file,uploadFileHeaders,uploadObjectNameFields);

            const responseData=await member.uploadUnionMembers(uploadData);

            if(responseData.data.status===1){
                await addRecordsToCache(queryClient,['membersList'],responseData.data.operatedData);
                displayMessage({
                    header:'Upload Success',
                    message:'Members were successfully uploaded',
                    infoType:'success',
                    toastComponent:toastRef,
                    life:5000
                });
            }

        }catch(error:any){
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }
    }
    const downloadMembersList = () => {
        try {
            downloadExcelFile(data, 'members_list', [ {
                    fullName: 'FULL NAME',
                    coyNumber: 'COY NUMBER',
                    sapNumber: 'SAP NUMBER',
                    firstName: 'FIRST NAME',
                    lastName: 'LAST NAME',
                    otherNames: 'OTHER NAMES',
                    emailAddress: 'EMAIL ADDRESS',
                    phoneNumber: 'PHONE NUMBER',
                    memberId: 'MEMBER ID',
                    memberName: 'MEMBER NAME',
                }]);
        } catch (error:any) {
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }finally {

        }
    };
    const downloadMembersUploadFile=()=>{
        try{
            downloadExcelFile([],'member_file',[
                {
                    sapNumber:'SAP NUMBER',
                    coyNumber:'COY NUMBER',
                    firstName:'FIRST NAME',
                    lastName:'LAST NAME',
                    otherNames:'OTHER NAMES',
                    emailAddress:'EMAIL ADDRESS',
                    phoneNumber:'PHONE NUMBER',
                }
            ]);
        }catch(error:any){
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }
    }
    return (
        <>
            {state.isLoading && <Loader/>}
            <GeneralPageProps toastRef={toastRef}/>
            <div className="p-fluid lg:pl-5">
                <SimpleTableWithMenu
                    tableKey={"memberId"}
                    columnsDef={
                        [
                            {body:(rowData)=><div className={rowData.memberStatus==='Active'?`bg-green-700 p-2`:`bg-pink-500 p-2`}>{rowData.memberStatus}</div>, header: 'Status'},
                            {field: 'fullName', header: 'Name'},
                            {field: 'emailAddress', header: 'Email'},
                            {field: 'phoneNumber', header: 'Phone'},
                            {field: 'coyNumber', header: 'COY #'},
                            {field: 'sapNumber', header: 'SAP #'},
                            {body: (rowData: IMember) => tableEditOption(setupMemberEdit, promptMemberDelete, rowData.memberId), header: 'Edit'},
                        ]
                    }
                    tableData={data}
                    menuModel={membershipMenu()}
                    hasMenuList={true}
                    tableTitle="Union Members"
                    lastTableUpdate={dataUpdatedAt}
                    searchValues={['sapNumber','coyNumber','fullName']}
                    searchFieldPlaceHolder="Search by Full Name, COY Number, SAP Number"
                />
            </div>
            <Dialog onHide={() => setStateValues({showDialog: false, editingState: false})} visible={state.showDialog}
                    header="New Member"
                    position="top-right"
                    onShow={onMemberDialogShown}
                    className="lg:w-7">
                <div className="p-fluid ">
                    <div className="grid p-formgrid bg">
                        <IconTextInput value={state.sapNumber} onInputChange={controlChange}
                                       placeholderValue="SAP #"
                                       iconText="pi pi-user" componentId="sapNumber"
                                       customClasses="lg:col-6 md:col-12 col-12"/>
                        <IconTextInput value={state.coyNumber} onInputChange={controlChange}
                                       placeholderValue="COY #"
                                       iconText="pi pi-user" componentId="coyNumber"
                                       customClasses="lg:col-6 md:col-12 col-12"/>
                        <IconTextInput value={state.firstName} onInputChange={controlChange}
                                       placeholderValue="First Name"
                                       iconText="pi pi-user" componentId="firstName"
                                       customClasses="lg:col-6 md:col-12 col-12"/>
                        <IconTextInput value={state.lastName} onInputChange={controlChange}
                                       placeholderValue="Last Name"
                                       iconText="pi pi-user" componentId="lastName"
                                       customClasses="lg:col-6 md:col-12 col-12"/>
                        <IconTextInput value={state.otherNames} onInputChange={controlChange}
                                       placeholderValue="Other Names"
                                       iconText="pi pi-user" componentId="otherNames"
                                       customClasses="lg:col-6 md:col-12 col-12"/>
                        <IconTextInput value={state.emailAddress} onInputChange={controlChange}
                                       placeholderValue="Email Address"
                                       iconText="pi pi-at" componentId="emailAddress"
                                       customClasses="lg:col-6 md:col-12 col-12"/>
                        <IconTextInput value={state.phoneNumber} onInputChange={controlChange}
                                       placeholderValue="Phone Number"
                                       iconText="pi pi-phone" componentId="phoneNumber"
                                       customClasses="lg:col-6 md:col-12 col-12"/>
                        <div className="lg:col-6 md:col-12 col-12">
                            <label>Member Status</label>
                            <SelectButton className="mt-2" value={state.memberStatus} onChange={(e)=>setStateValues({memberStatus:e.value})} options={['Active','Resigned']} />
                        </div>
                    </div>
                    <div className="grid lg:mt-2">
                        <div className="lg:col-6 md:col-12 col-12">
                            <Button onClick={!state.editingState ? promptMemberSave : promptMemberUpdate}>{!state.editingState ? `Save Member` : `Update Member`}</Button>
                        </div>
                        <div className="lg:col-6 md:col-12 col-12">
                            {state.isLoading && <DefaultSpinner customClasses="text-3xl lg:ml-5 mt-2"/>}
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog onHide={()=>{setStateValues({showUploaderDialog:false})}} visible={state.showUploaderDialog} position="bottom-right">
                <FileUploader onFileUpload={onUploadFile} fileUploadRef={uploadComponentRef} id="membersUploader" acceptableFileTypes=".xlsx,.csv"/>
            </Dialog>
        </>
    )
}
export default Membership;
