import {IOneForAll} from "../helpers/interfaces/IOneForAll";
import {IInvestmentInstruments} from "../helpers/typesUtils";
import {AxiosResponse} from "axios";
import {fetchAction, getBaseURL, sliceObject} from "../helpers/utils";
import {format} from "date-fns";

class Investments implements IOneForAll<IInvestmentInstruments>,IInvestmentInstruments{
    amountInvested: string='';
    institution: string='';
    investmentBegins: string | Date | Date[]=new Date();
    investmentEnds: string | Date | Date[]=new Date();
    investmentInstrumentId: string='';
    investmentState: "Rolling" | "Not Rolling"='Rolling';
    expectedInterest: string='';

    async createInstance(): Promise<AxiosResponse<{status:number,operatedData:IInvestmentInstruments,error?:string}>> {
        const requestBody=this.getInstance();
        return await fetchAction('post', `${getBaseURL()}/investment_instruments/new_instrument`, {requestBody});
    }

    async deleteInstance(deletingInvestmentId: string):Promise<AxiosResponse<{status:number,operatedData:IInvestmentInstruments,error?:string}>> {
        return await fetchAction('delete',`${getBaseURL()}/investment_instruments/delete_instrument`, {deletingInvestmentId});
    }

    getInstance(): Partial<IInvestmentInstruments> {
        const props:Array<keyof IInvestmentInstruments>=["investmentInstrumentId",
            "institution","investmentBegins","investmentEnds","expectedInterest",
            "investmentState","amountInvested"];
        return sliceObject(props,this);
    }

    makeInstance(stateValues: IInvestmentInstruments): void {
        const {investmentInstrumentId,institution,
            investmentBegins,investmentEnds,
            expectedInterest,investmentState,amountInvested}=stateValues;
        this.investmentInstrumentId=investmentInstrumentId;
        this.investmentBegins=format(new Date(investmentBegins as Date),'yyyy-MM-dd');
        this.investmentEnds=format(new Date(investmentEnds as Date),'yyyy-MM-dd');
        this.expectedInterest=expectedInterest;
        this.investmentState=investmentState;
        this.amountInvested=amountInvested;
        this.institution=institution
    }

    async updateInstance(): Promise<AxiosResponse<{status:number,operatedData:IInvestmentInstruments,error?:string}>> {
        const requestBody=this.getInstance();
        return await fetchAction('patch', `${getBaseURL()}/investment_instruments/update_instrument`, {requestBody});
    }
}
export default Investments;
