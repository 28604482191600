import {DatePicker, GeneralPageProps, IconTextInput, Loader} from "../helpers/components";
import React, {useReducer, useRef} from "react";
import {Toast} from "primereact/toast";
import {Button} from "primereact/button";
import {IOtherPostings, IReportsInterface, TGeneralPage, TReportData} from "../helpers/typesUtils";
import Reports from "../classes/Reports";
import {customReducer, displayMessage, formatCell, inputChange, REDUCER_ACTION_TYPE} from "../helpers/utils";
import {getYear} from "date-fns";
import {CalendarChangeEvent} from "primereact/calendar";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
const ExcelJS = require('exceljs');
const _=require('lodash');
type TPostData=Pick<IOtherPostings, 'postingDescription'|'accumulatedPostingAmount'>&{sameInstrumentAccumulatedAmount:string|number}
type TReportInterface=IReportsInterface & TGeneralPage<IReportsInterface>
const INITIAL_STATE:TReportInterface={
    unionDescription: "",
    unionLocation: "",
    applicationStartYear: new Date(),
    startingBalance: "",
    editingObjectId: "",
    editingState: false,
    isLoading: false,
    showDialog: false,
    reportYear: new Date()

}
const reports=new Reports();
const ReportsInterface=()=>{
    const [state,dispatch]=useReducer(customReducer<TReportInterface>,INITIAL_STATE);
    const toastRef = useRef<Toast>(null);

    const setStateValues = (stateValues: Partial<TReportInterface>) => {
        dispatch({
            type: REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES,
            payload: {...stateValues}
        });
    }
    const fetchReportData=async ()=>{
        try{
            const fetchReportData=await reports.getReportData(getYear(new Date(state.reportYear as Date)));

            const otherPostings=typeof fetchReportData.data.otherPostings==='string'?JSON.parse(fetchReportData.data.otherPostings):fetchReportData.data.otherPostings;
            const otherPostingsPreviousYear=typeof fetchReportData.data.otherPostingsPreviousYear==='string'?JSON.parse(fetchReportData.data.otherPostingsPreviousYear):fetchReportData.data.otherPostingsPreviousYear;

            const reportData:TReportData[]=typeof fetchReportData.data.reportData==='string'?JSON.parse(fetchReportData.data.reportData):fetchReportData.data.reportData;
            const reportDataPreviousYear:TReportData[]=typeof fetchReportData.data.reportDataPreviousYear==='string'?JSON.parse(fetchReportData.data.reportDataPreviousYear):fetchReportData.data.reportDataPreviousYear;


            const currentYearData=getReportYearData(reportData[0],otherPostings);

            const previousYearData=getReportYearData(reportDataPreviousYear[0],otherPostingsPreviousYear);

            return {currentYearData:currentYearData,previousYearData:previousYearData,
                currentYearPreviousYearBF:fetchReportData.data.currentYPreviousYearBF,
                previousYearPreviousYearBF:fetchReportData.data.previousYearPreviousYearBF}
        }catch(error:any){

            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
            throw Error(error.message);

        }
    }
    const getReportYearData=(sanitizedData:TReportData,otherPostings:IOtherPostings[])=>{

        const currentAssets=getSheetGroupingsValue('Current Asset',otherPostings);
        const currentLiabilities=getSheetGroupingsValue('Current Liability',otherPostings);
        const incomes=getSheetGroupingsValue('Income',otherPostings);
        const expenditure=getSheetGroupingsValue('Expenditure',otherPostings);
        const dividend=getSheetGroupingsValue('Proposed Dividend',otherPostings);
        const reserves=getSheetGroupingsValue('Reserves',otherPostings);

        const outstandingLoans=sanitizedData.outstandingLoans;
        const otherReceivables=sanitizedData.otherReceivables;
        const interestOnLoanReceivable=sanitizedData.interestOnLoansReceivable;
        const investmentInterestReceivable=sanitizedData.investmentInterestReceivable;
        const investments=sanitizedData.investments;
        const membersContributions=sanitizedData.membersContributions;
        const interestOnLoans=sanitizedData.interestOnLoans;

        const totalCurrentAssets=parseFloat(outstandingLoans)+parseFloat(otherReceivables)+
            parseFloat(interestOnLoanReceivable)+parseFloat(investmentInterestReceivable)+parseFloat(investments);

        const totalCurrentLiabilities=parseFloat(membersContributions);

        const totalIncome=parseFloat(investmentInterestReceivable)+parseFloat(interestOnLoans);

        return {outstandingLoans,otherReceivables,interestOnLoanReceivable,
            investmentInterestReceivable,investments,membersContributions,
            currentAssets,currentLiabilities,interestOnLoans,incomes,expenditure,
            totalCurrentAssets,totalCurrentLiabilities,totalIncome,dividend,reserves}
    }
    const getSheetGroupingsValue=(postType:'Current Asset'|'Fixed Asset'|'Current Liability'|'Income'|'Expenditure'|'Proposed Dividend'|'Reserves',posts:IOtherPostings[])=>{
        /*
        sheet groupings can be current asset,fixed asset, current liability, long term liability;
         */
        return posts.filter((post:IOtherPostings)=>post.postingType===postType);
    }
    const generateExcel=async ()=>{
        try{
            setStateValues({isLoading:true});
            const {currentYearData,previousYearData,currentYearPreviousYearBF,previousYearPreviousYearBF}=await fetchReportData();

            let currentYearTotalAssets=currentYearData.totalCurrentAssets;

            let currentYearTotalLiabilities=currentYearData.totalCurrentLiabilities;
            let currentYearTotalIncome=currentYearData.totalIncome;
            let currentYearTotalExpenses=0;

            let previousYearTotalExpenses=0;
            let previousYearTotalAssets=previousYearData.totalCurrentAssets;

            let previousYearTotalLiabilities=previousYearData.totalCurrentLiabilities;
            let previousYearTotalIncome=previousYearData.totalIncome;

            const reportCurrentYear=getYear(new Date(state.reportYear as Date));
            const reportPreviousYear=reportCurrentYear-1;


            const workbook=new ExcelJS.Workbook();
            const sheet=workbook.addWorksheet(`report ${reportCurrentYear}`,{properties:{tabColor:{argb:'00CC00'}}});

            sheet.addRow(['','GOLD FIELDS GHANA LIMITED']);
            sheet.addRow(['','TARKWA GOLD MINE']);
            sheet.addRow(['','OFFICIALS CREDIT UNION']);
            sheet.addRow([`YEAR ${reportPreviousYear}`,`BALANCE SHEET AS AT 31ST DECEMBER ${reportCurrentYear}`,'',`YEAR ${reportCurrentYear}`]);
            sheet.addRow(['']);
            sheet.addRow(['','CURRENT ASSETS']);
            sheet.addRow([parseFloat(previousYearData.outstandingLoans),'Outstanding Loans','',parseFloat(currentYearData.outstandingLoans)]);


            const accumulatedCurrentAssets=insertOtherPostings(currentYearData.currentAssets,previousYearData.currentAssets);

            addReportSheetRow(sheet,accumulatedCurrentAssets.sheetData);
            currentYearTotalAssets+=accumulatedCurrentAssets.currentYearAccumulatedPostAmount;
            previousYearTotalAssets+=accumulatedCurrentAssets.previousYearAccumulatedPostAmount;

            sheet.addRow([parseFloat(previousYearData.otherReceivables),`Other Receivables [Member Contributions - ${reportCurrentYear}]`,'',parseFloat(currentYearData.otherReceivables)]);
            sheet.addRow([parseFloat(previousYearData.interestOnLoanReceivable),'Interest On Loans Receivable','',parseFloat(currentYearData.interestOnLoanReceivable)]);
            sheet.addRow([parseFloat(previousYearData.investmentInterestReceivable),'Investments Interest Receivable','',parseFloat(currentYearData.investmentInterestReceivable)]);
            sheet.addRow([parseFloat(previousYearData.investments),'Investments','',parseFloat(currentYearData.investments)]);
            sheet.addRow([previousYearTotalAssets,'TOTAL CURRENT ASSETS','',currentYearTotalAssets]);
            sheet.addRow(['','LESS CURRENT LIABILITIES','','']);
            sheet.addRow([parseFloat(previousYearData.membersContributions),'Members Contributions','',parseFloat(currentYearData.membersContributions)]);

            const accumulatedDividend=insertOtherPostings(currentYearData.dividend,previousYearData.dividend);
            addReportSheetRow(accumulatedDividend,accumulatedDividend.sheetData);


            const accumulatedCurrentLiabilities=insertOtherPostings(currentYearData.currentLiabilities,previousYearData.currentLiabilities);
            addReportSheetRow(sheet,accumulatedCurrentLiabilities.sheetData);
            currentYearTotalLiabilities+=accumulatedCurrentLiabilities.currentYearAccumulatedPostAmount+accumulatedDividend.currentYearAccumulatedPostAmount;
            previousYearTotalLiabilities+=accumulatedCurrentLiabilities.previousYearAccumulatedPostAmount+accumulatedDividend.previousYearAccumulatedPostAmount;

            sheet.addRow([previousYearTotalLiabilities,'TOTAL CURRENT LIABILITIES','',currentYearTotalLiabilities]);

            sheet.addRow(['','','','']);
            sheet.addRow(['','','','']);
            sheet.addRow([previousYearTotalAssets-previousYearTotalLiabilities,'TOTAL NET ASSETS','',currentYearTotalAssets-currentYearTotalLiabilities]);


            sheet.addRow(['','']);
            sheet.addRow(['','']);
            sheet.addRow(['',sheet.getCell(`B1`).value]);
            sheet.addRow(['',sheet.getCell(`B2`).value]);
            sheet.addRow(['',sheet.getCell(`B3`).value]);
            sheet.addRow([`YEAR ${reportPreviousYear}`,`INCOME STATEMENT FOR THE YEAR ENDED 31 DECEMBER ${reportCurrentYear}`,'',`YEAR ${reportCurrentYear}`]);
            sheet.addRow(['']);
            sheet.addRow(['','INCOME']);
            sheet.addRow(['','']);
            sheet.addRow([parseFloat(previousYearData.interestOnLoans),'Interest On Loans','',parseFloat(currentYearData.interestOnLoans)]);


            const accumulatedIncomes=insertOtherPostings(currentYearData.incomes,previousYearData.incomes);
            addReportSheetRow(sheet,accumulatedIncomes.sheetData);
            currentYearTotalIncome+=accumulatedIncomes.currentYearAccumulatedPostAmount;
            previousYearTotalIncome+=accumulatedIncomes.previousYearAccumulatedPostAmount;

            sheet.addRow([parseFloat(previousYearData.investmentInterestReceivable),'Investments Interest Receivable','',parseFloat(currentYearData.investmentInterestReceivable)]);
            sheet.addRow([previousYearTotalIncome,'TOTAL INCOME','',currentYearTotalIncome]);

            sheet.addRow(['','EXPENDITURE','','']);

            const accumulatedExpenditures=insertOtherPostings(currentYearData.expenditure,previousYearData.expenditure);
            addReportSheetRow(sheet,accumulatedExpenditures.sheetData);
            currentYearTotalExpenses+=accumulatedExpenditures.currentYearAccumulatedPostAmount;
            previousYearTotalExpenses+=accumulatedExpenditures.previousYearAccumulatedPostAmount;

            const previousYearNetIncome=previousYearTotalIncome-previousYearTotalExpenses;
            const currentYearNetIncome=currentYearTotalIncome-currentYearTotalExpenses;

            sheet.addRow([previousYearTotalExpenses,'Total Expense','',currentYearTotalExpenses]);
            sheet.addRow(['','','','']);
            sheet.addRow([previousYearNetIncome,'NET INCOME','',currentYearNetIncome]);
            sheet.addRow(['','','','']);

            sheet.addRow(['','INCOME SURPLUS ACCOUNT','','']);

            sheet.addRow([previousYearPreviousYearBF,'Balance B/F','',currentYearPreviousYearBF]);

            const accumulatedReserves=insertOtherPostings(currentYearData.reserves,previousYearData.reserves);
            addReportSheetRow(sheet,accumulatedReserves.sheetData);
            const currentYearReserves=accumulatedReserves.currentYearAccumulatedPostAmount;
            const previousYearReserves=accumulatedReserves.previousYearAccumulatedPostAmount;

            sheet.addRow([previousYearNetIncome,'Surplus For the Year','',currentYearNetIncome]);

            sheet.addRow([previousYearReserves,'Reserves','',currentYearReserves]);

            const currentYearSurplus=currentYearPreviousYearBF+currentYearNetIncome+currentYearReserves;
            const previousYearSurplus=previousYearPreviousYearBF+previousYearNetIncome+previousYearReserves;

            sheet.addRow([previousYearSurplus,'NET SURPLUS','',currentYearSurplus]);


            addReportSheetRow(accumulatedDividend,accumulatedDividend.sheetData);

            const currentYearDividend=accumulatedDividend.currentYearAccumulatedPostAmount;
            const previousYearDividend=accumulatedDividend.previousYearAccumulatedPostAmount;

            sheet.addRow(['','LESS PROPOSED DIVIDEND','','']);

            sheet.addRow([previousYearDividend,'Dividend Payment','',currentYearDividend]);

            const currentYearBalCF=currentYearNetIncome-currentYearDividend+currentYearPreviousYearBF;
            const previousYearBalCF=previousYearNetIncome-previousYearDividend+previousYearPreviousYearBF;

            sheet.addRow([previousYearBalCF,'Balance C/F','',currentYearBalCF]);

            sheet.getColumn('B').eachCell((cell:any,rowNumber:number)=>{
                sheet.getCell(`D${rowNumber}`).numFmt='#,##0.00';
                sheet.getCell(`A${rowNumber}`).numFmt='#,##0.00';
                if(cell.value==='TOTAL CURRENT ASSETS' || cell.value==='TOTAL CURRENT LIABILITIES'||cell.value==='NET SURPLUS' || cell.value==='Total Expense' || cell.value==='TOTAL INCOME'){
                    sheet.getCell(`D${rowNumber-1}`).font=formatCell({bold:false,underline:'single'});
                    sheet.getCell(`A${rowNumber-1}`).font=formatCell({bold:false,underline:'single'});
                    sheet.getCell(`D${rowNumber}`).font=formatCell({bold:true,underline:''});
                    sheet.getCell(`A${rowNumber}`).font=formatCell({bold:true,underline:''});
                    cell.value='';
                }
                if(cell.value==='CURRENT ASSETS' || cell.value==='LESS CURRENT LIABILITIES'|| cell.value==='TOTAL NET ASSETS'
                    || cell.value==='INCOME' || cell.value==='EXPENDITURE' || cell.value==='NET INCOME' || cell.value==='INCOME SURPLUS ACCOUNT'){
                    cell.font=formatCell({size:12,bold:true,underline:'single'})
                }
                if(cell.value==='TOTAL NET ASSETS'){
                    cell.font=formatCell({size:12,bold:true,underline:''});
                    sheet.getCell(`A${rowNumber}`).font=formatCell({bold:true,underline:''});
                    sheet.getCell(`D${rowNumber}`).font=formatCell({bold:true,underline:''});
                }
                if(cell.value==='Balance C/F'){
                    sheet.getCell(`A${rowNumber}`).font=formatCell({bold:true,underline:'double'});
                    sheet.getCell(`D${rowNumber}`).font=formatCell({bold:true,underline:'double'});
                }
                if(cell.value==='GOLD FIELDS GHANA LIMITED' || cell.value==='TARKWA GOLD MINE' || cell.value==='OFFICIALS CREDIT UNION'
                    || cell.value===`INCOME STATEMENT FOR THE YEAR ENDED 31 DECEMBER ${reportCurrentYear}` || cell.value===`BALANCE SHEET AS AT 31ST DECEMBER ${reportCurrentYear}`){
                    cell.font=formatCell({size:12,bold:true,underline:'N/A'});
                }
            });



            //SHEET TWO STARTS HERE
            const sheet2=workbook.addWorksheet(`Financial Position ${reportCurrentYear}`,{properties:{tabColor:{argb:'FF0000'}},headerFooter:{firstHeader:'GOLD FIELDS OFFICIALS CREDIT UNION'}});
            sheet2.addRow(['GOLD FIELDS OFFICIALS CREDIT UNION']);
            sheet2.addRow(['ANNUAL REPORT AND FINANCIAL STATEMENTS']);
            sheet2.addRow([`FOR THE YEAR ENDED ${reportCurrentYear}`]);
            sheet2.addRow([`STATEMENT OF FINANCIAL POSITION AS AT 31 DECEMBER ${reportCurrentYear}`]);
            sheet2.addRow([``]);
            sheet2.addRow(['','CURRENT ASSETS','','','','','',`YEAR ${reportCurrentYear}`,'','',`YEAR ${reportPreviousYear}`]);
            sheet2.addRow(['','Outstanding Loans','','','','','',parseFloat(currentYearData.outstandingLoans),'','',parseFloat(previousYearData.outstandingLoans)]);
            sheet2.addRow(['','Interest On Loan Receivables','','','','','',parseFloat(currentYearData.interestOnLoanReceivable),'','',parseFloat(previousYearData.interestOnLoanReceivable)]);
            sheet2.addRow(['','Other Receivables','','','','','',parseFloat(currentYearData.otherReceivables),'','',parseFloat(previousYearData.otherReceivables)]);


            addFinancialPositionRow(sheet2,accumulatedCurrentAssets.sheetData);
            sheet2.addRow(['','Interest On Investment','','','','','',parseFloat(currentYearData.investmentInterestReceivable),'','',parseFloat(previousYearData.investmentInterestReceivable)]);
            sheet2.addRow(['','Investment','','','','','',parseFloat(currentYearData.investments),'','',parseFloat(previousYearData.investments)]);

            const financialPositionTotalAssetsCurrent=getSumOf([parseFloat(currentYearData.outstandingLoans),parseFloat(currentYearData.interestOnLoanReceivable)
                ,parseFloat(currentYearData.otherReceivables),accumulatedCurrentAssets.currentYearAccumulatedPostAmount,parseFloat(currentYearData.investmentInterestReceivable),parseFloat(currentYearData.investments)]);
            const financialPositionTotalAssetsPrevious=getSumOf([parseFloat(previousYearData.outstandingLoans),parseFloat(previousYearData.interestOnLoanReceivable)
                ,parseFloat(previousYearData.otherReceivables)+accumulatedCurrentAssets.previousYearAccumulatedPostAmount,parseFloat(previousYearData.investmentInterestReceivable),parseFloat(previousYearData.investments)]);

            sheet2.addRow(['','TOTAL ASSETS','','','','','',financialPositionTotalAssetsCurrent,'','',financialPositionTotalAssetsPrevious]);

            sheet2.addRow(['','','','','','','','']);
            sheet2.addRow(['','LESS LIABILITIES','','','','','','']);
            addFinancialPositionRow(sheet2,accumulatedCurrentLiabilities.sheetData);

            const financialPositionCurrentYearTotalLiabilities=accumulatedCurrentLiabilities.currentYearAccumulatedPostAmount+parseFloat(currentYearData.membersContributions);
            const financialPositionPreviousYearTotalLiabilities=accumulatedCurrentLiabilities.previousYearAccumulatedPostAmount+parseFloat(previousYearData.membersContributions);

            sheet2.addRow(['','Members Contributions','','','','','',parseFloat(currentYearData.membersContributions),'','',parseFloat(previousYearData.membersContributions)]);


            sheet2.addRow(['','TOTAL LIABILITIES','','','','','',financialPositionCurrentYearTotalLiabilities,'','',financialPositionPreviousYearTotalLiabilities]);
            sheet2.addRow(['','','','','','','','']);
            sheet2.addRow(['','','','','','','','']);
            sheet2.addRow(['','NET ASSETS','','','','','',financialPositionTotalAssetsCurrent-financialPositionCurrentYearTotalLiabilities,'','',financialPositionTotalAssetsPrevious-financialPositionPreviousYearTotalLiabilities]);

            sheet2.addRow(['','','','','','','','']);
            sheet2.addRow(['','','','','','','','']);
            sheet2.addRow(['','','','','','','','']);
            sheet2.addRow(['',`SHAREHOLDER'S FUND`,'','','','','','']);
            sheet2.addRow(['','Accumulated Income Surplus','','','','','',currentYearBalCF,'','',previousYearBalCF]);

            sheet2.getColumn('B').eachCell((cell:any,rowNumber:number)=>{
                sheet2.getCell(`H${rowNumber}`).numFmt='#,##0.00';
                sheet2.getCell(`K${rowNumber}`).numFmt='#,##0.00';
                if(cell.value==='CURRENT ASSETS' || cell.value==='TOTAL ASSETS' || cell.value==='LESS LIABILITIES'){
                    cell.font=formatCell({size:12,bold:true,underline:'single'});
                }
                if(cell.value==='TOTAL LIABILITIES'){
                    sheet2.getCell(`H${rowNumber}`).font=formatCell({bold:true,underline:'single'});
                    sheet2.getCell(`K${rowNumber}`).font=formatCell({bold:true,underline:'single'});
                    cell.value='';
                }
                if(cell.value==='TOTAL ASSETS'){
                    sheet2.getCell(`H${rowNumber}`).font=formatCell({bold:true,underline:'single'});
                    sheet2.getCell(`K${rowNumber}`).font=formatCell({bold:true,underline:'single'});
                    cell.font=formatCell({size:12,bold:true,underline:''});
                }
                if(cell.value==='NET ASSETS'){
                    sheet2.getCell(`H${rowNumber}`).font=formatCell({bold:true,underline:'double'});
                    sheet2.getCell(`K${rowNumber}`).font=formatCell({bold:true,underline:'double'});
                    cell.font=formatCell({size:12,bold:true,underline:''});
                }
                if(cell.value==='LESS LIABILITIES'){
                    cell.font=formatCell({size:12,bold:true,underline:'single'});
                }
                if(cell.value===`SHAREHOLDER'S FUND`){
                    cell.font=formatCell({size:12,bold:true,underline:'single'});
                }
                if(cell.value===`Accumulated Income Surplus`){
                    sheet2.getCell(`H${rowNumber}`).font=formatCell({bold:true,underline:'double'});
                    sheet2.getCell(`K${rowNumber}`).font=formatCell({bold:true,underline:'double'});
                }
            });
            sheet2.mergeCells('A1:K1');
            sheet2.mergeCells('A2:K2');
            sheet2.mergeCells('A3:K3');
            sheet2.mergeCells('A4:K4');
            sheet2.getCell('A1').alignment={ horizontal: 'center', vertical: 'middle' };
            sheet2.getCell('A2').alignment={ horizontal: 'center', vertical: 'middle' };
            sheet2.getCell('A3').alignment={ horizontal: 'center', vertical: 'middle' };
            sheet2.getCell('A4').alignment={ horizontal: 'center', vertical: 'middle' };
            sheet2.getCell('A1').font=formatCell({bold:true,underline:'',size:12});
            sheet2.getCell('A2').font=formatCell({bold:true,underline:'',size:12});
            sheet2.getCell('A3').font=formatCell({bold:true,underline:'',size:12});
            sheet2.getCell('A4').font=formatCell({bold:true,underline:'',size:12});
            //SHEET THREE STARTS HERE
            const sheet3=workbook.addWorksheet(`NOTES 3-6 ${reportCurrentYear}`,{properties:{tabColor:{argb:'FFC0000'}}});

            sheet3.addRow(['NOTES TO THE FINANCIAL STATEMENTS']);
            sheet3.addRow(['','REVENUE','','',`YEAR ${reportCurrentYear}`,`YEAR ${reportPreviousYear}`]);
            sheet3.addRow(['']);
            sheet3.addRow(['','Interest On Loans','','',parseFloat(currentYearData.interestOnLoans),parseFloat(previousYearData.interestOnLoans)]);
            sheet3.addRow(['','Interest On Investments','','',parseFloat(currentYearData.investmentInterestReceivable),parseFloat(previousYearData.investmentInterestReceivable)]);

            addNotesRow(sheet3,accumulatedIncomes.sheetData);
            const currentYearRevenue=getSumOf([parseFloat(currentYearData.interestOnLoans),parseFloat(currentYearData.investmentInterestReceivable),accumulatedIncomes.currentYearAccumulatedPostAmount]);
            const previousYearRevenue=getSumOf([parseFloat(previousYearData.interestOnLoans),parseFloat(previousYearData.investmentInterestReceivable),accumulatedIncomes.previousYearAccumulatedPostAmount]);
            sheet3.addRow(['','Total Revenue','','',currentYearRevenue,previousYearRevenue]);

            sheet3.addRow(['','EXPENDITURE','','','','']);
            addNotesRow(sheet3,accumulatedExpenditures.sheetData);
            sheet3.addRow(['','TOTAL EXPENSES','','',accumulatedExpenditures.currentYearAccumulatedPostAmount,accumulatedExpenditures.previousYearAccumulatedPostAmount]);

            sheet3.addRow(['','','','','','']);
            sheet3.addRow(['','RECEIVABLES','','','','']);
            sheet3.addRow(['','','','','','']);
            sheet3.addRow(['','Interest On Loans Receivables','','',parseFloat(currentYearData.interestOnLoanReceivable),parseFloat(previousYearData.interestOnLoanReceivable)]);
            sheet3.addRow(['','Other Receivables','','',parseFloat(currentYearData.otherReceivables),parseFloat(previousYearData.otherReceivables)]);
            sheet3.addRow(['','Interest On Investments','','',parseFloat(currentYearData.investmentInterestReceivable),parseFloat(previousYearData.investmentInterestReceivable)]);
            const currentYearReceivables=getSumOf([parseFloat(currentYearData.interestOnLoanReceivable),parseFloat(currentYearData.otherReceivables),parseFloat(currentYearData.investmentInterestReceivable)])
            const previousYearReceivables=getSumOf([parseFloat(previousYearData.interestOnLoanReceivable),parseFloat(previousYearData.otherReceivables),parseFloat(previousYearData.investmentInterestReceivable)])
            sheet3.addRow(['','TOTAL RECEIVABLES','','',currentYearReceivables,previousYearReceivables]);

            const currentYearCashAtBankFromCurrentAssets=currentYearData.currentAssets.filter((currentAsset:IOtherPostings)=>currentAsset.postingDescription==='Cash At Bank');
            const previousYearCashAtBankFromCurrentAssets=previousYearData.currentAssets.filter((currentAsset:IOtherPostings)=>currentAsset.postingDescription==='Cash At Bank');
            const accumulatedCashAtBank=insertOtherPostings(currentYearCashAtBankFromCurrentAssets,previousYearCashAtBankFromCurrentAssets);
            sheet3.addRow(['','','','','','']);
            sheet3.addRow(['','','','','','']);
            sheet3.addRow(['','','','','','']);
            sheet3.addRow(['','CASH AND CASH EQUIVALENTS','','','','']);
            sheet3.addRow(['','','','','','']);
            sheet3.addRow(['','Cash At Bank','','',accumulatedCashAtBank.currentYearAccumulatedPostAmount,accumulatedCashAtBank.previousYearAccumulatedPostAmount])

            sheet3.getColumn('B').eachCell((cell:any,rowNumber:number)=>{
                sheet3.getCell(`E${rowNumber}`).numFmt='#,##0.00';
                sheet3.getCell(`F${rowNumber}`).numFmt='#,##0.00';
                if(cell.value==='REVENUE' || cell.value==='EXPENDITURE' || cell.value==='RECEIVABLES' ||cell.value=== 'CASH AND CASH EQUIVALENTS'){
                    cell.font=formatCell({size:12,bold:true,underline:''});
                    sheet3.getCell(`E${rowNumber}`).font=formatCell({bold:true,underline:''});
                    sheet3.getCell(`F${rowNumber}`).font=formatCell({bold:true,underline:''});
                }
                if(cell.value==='Total Revenue'|| cell.value==='TOTAL EXPENSES' || cell.value==='TOTAL RECEIVABLES'){
                    sheet3.getCell(`E${rowNumber}`).font=formatCell({bold:true,underline:'single'});
                    sheet3.getCell(`F${rowNumber}`).font=formatCell({bold:true,underline:'single'});
                    sheet3.getCell(`E${rowNumber-1}`).font=formatCell({bold:false,underline:'single'});
                    sheet3.getCell(`F${rowNumber-1}`).font=formatCell({bold:false,underline:'single'});
                    cell.value='';
                }
                if(cell.value==='Cash At Bank'){
                    sheet3.getCell(`E${rowNumber}`).font=formatCell({bold:true,underline:'double'});
                    sheet3.getCell(`F${rowNumber}`).font=formatCell({bold:true,underline:'double'});
                }
            });
            sheet3.mergeCells('A1:F1');
            sheet3.getCell('A1').alignment={ horizontal: 'center', vertical: 'middle' };
            sheet3.getCell('A1').font=formatCell({bold:true,underline:'',size:12});
            //SHEET 4 BEGINS HERE
            const sheet4=workbook.addWorksheet(`Income Statement`,{properties:{tabColor:{argb:'FFC0000'}}});
            const surplusForCurrentYear=currentYearRevenue-accumulatedExpenditures.currentYearAccumulatedPostAmount;
            const surplusForPreviousYear=previousYearRevenue-accumulatedExpenditures.previousYearAccumulatedPostAmount;
            sheet4.addRow([`INCOME STATEMENT FOR THE YEAR ENDED 31 DECEMBER ${reportCurrentYear}`,'','','','','','']);
            sheet4.addRow([``,'','','','','','']);
            sheet4.addRow([``,'','','','NOTES',`YEAR ${reportCurrentYear}`,`YEAR ${reportPreviousYear}`]);
            sheet4.addRow([``,'','','','','','']);
            sheet4.addRow([`Revenue`,'','','','',currentYearRevenue,previousYearRevenue]);
            sheet4.addRow([``,'','','','','','']);
            sheet4.addRow([`Expenditure`,'','','','',accumulatedExpenditures.currentYearAccumulatedPostAmount,accumulatedExpenditures.previousYearAccumulatedPostAmount]);
            sheet4.addRow([``,'','','','','','']);
            sheet4.addRow([``,'','','','','','']);
            sheet4.addRow([`Surplus for the year`,'','','','',surplusForCurrentYear,surplusForPreviousYear]);
            sheet4.getColumn('A').eachCell((cell:any,rowNumber:number)=>{
                sheet4.getCell(`F${rowNumber}`).numFmt='#,##0.00';
                sheet4.getCell(`G${rowNumber}`).numFmt='#,##0.00';
                cell.font=formatCell({size:12,underline:''})
                if(cell.value==='Expenditure'){
                    sheet4.getCell(`F${rowNumber}`).font=formatCell({bold:false,underline:'single'});
                    sheet4.getCell(`G${rowNumber}`).font=formatCell({bold:false,underline:'single'});
                }
                if(cell.value==='Surplus for the year'){
                    sheet4.getCell(`F${rowNumber}`).font=formatCell({bold:true,underline:'double'});
                    sheet4.getCell(`G${rowNumber}`).font=formatCell({bold:true,underline:'double'});
                }
            });
            sheet4.mergeCells('A1:G1');
            sheet4.getCell('A1').alignment={ horizontal: 'center', vertical: 'middle' };
            sheet4.getCell('A1').font=formatCell({bold:true,underline:'',size:12});
            sheet4.getCell('E3').font=formatCell({bold:true,underline:'',size:12});
            sheet4.getCell('F3').font=formatCell({bold:true,underline:'',size:12});
            sheet4.getCell('G3').font=formatCell({bold:true,underline:'',size:12});
            //SHEET 5;
            const sheet5=workbook.addWorksheet(`Changes In Equity`,{properties:{tabColor:{argb:'FFC0000'}}});
            sheet5.addRow(['STATEMENT OF CHANGES IN EQUITY','','','','','','']);
            sheet5.addRow(['','','','','','',`YEAR ${reportCurrentYear}`,`YEAR ${reportPreviousYear}`]);
            sheet5.addRow(['Income Surplus B/F','','','','','',currentYearPreviousYearBF,previousYearPreviousYearBF]);

            sheet5.addRow(['Surplus For the Year','','','','','',currentYearNetIncome,previousYearNetIncome]);

            sheet5.addRow(['Bal C/F','','','','','',currentYearSurplus,previousYearSurplus]);

            sheet5.getColumn('A').eachCell((cell:any,rowNumber:number)=>{
                sheet5.getCell(`F${rowNumber}`).numFmt='0.00';
                sheet5.getCell(`G${rowNumber}`).numFmt='0.00';

            });
            sheet5.mergeCells('A1:H1');
            sheet5.getCell('A1').alignment={ horizontal: 'center', vertical: 'middle' };
            sheet5.getCell('B1').font=formatCell({bold:true,underline:'',size:12});
            sheet5.getCell('G2').font=formatCell({bold:true,underline:'',size:12});
            sheet5.getCell('H2').font=formatCell({bold:true,underline:'',size:12});

            sheet5.getColumn('A').eachCell((cell:any,rowNumber:number)=>{
                sheet4.getCell(`G${rowNumber}`).numFmt='#,##0.00';
                sheet4.getCell(`H${rowNumber}`).numFmt='#,##0.00';
                cell.font=formatCell({size:12,underline:''})

                if(cell.value==='Bal C/F'){
                    sheet5.getCell(`G${rowNumber}`).font=formatCell({bold:true,underline:'double'});
                    sheet5.getCell(`H${rowNumber}`).font=formatCell({bold:true,underline:'double'});
                }
            });

            workbook.xlsx.writeBuffer().then(function (data:any) {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'reports.xlsx';
                a.click();
            });
        }catch(error:any){
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }finally {
            setStateValues({isLoading:false});
        }

    }
    const addReportSheetRow=(sheet:any,postingData:TPostData[])=>{
        postingData.forEach((postData:TPostData)=>{
            sheet.addRow([postData.sameInstrumentAccumulatedAmount,postData.postingDescription,'',parseFloat(postData.accumulatedPostingAmount!)]);
        });
    }
    const addFinancialPositionRow=(sheet:any,postingData:TPostData[])=>{
        postingData.forEach((postData:TPostData)=>{
            sheet.addRow(['',postData.postingDescription,'','','','','',postData.accumulatedPostingAmount,'','',parseFloat(postData.sameInstrumentAccumulatedAmount!.toString())]);
        });

    }
    const addNotesRow=(sheet:any,postingData:TPostData[])=>{
        postingData.forEach((postData:TPostData)=>{
            sheet.addRow(['',postData.postingDescription,'','',postData.accumulatedPostingAmount,postData.sameInstrumentAccumulatedAmount]);
        });

    }
    const getSumOf=(numberValues:number[])=>{
        return numberValues.reduce((previousValue:number, currentValue, currentIndex)=>{return previousValue+=currentValue},0)
    }
    const onReportDateChange=(e:CalendarChangeEvent)=>{
        setStateValues({
            reportYear:e.value!
        });
    }
    const insertOtherPostings=(otherPostings:IOtherPostings[],previousYearPostings:IOtherPostings[])=>{
        try{
            let currentYearAccumulatedPostAmount=0;
            let previousYearAccumulatedPostAmount=0;
            let lastYearPostings=[...previousYearPostings];
            const sheetData:TPostData[]=[];
            for(const post of otherPostings){
                currentYearAccumulatedPostAmount+=parseFloat(post.accumulatedPostingAmount!);

                const sameInstrument=lastYearPostings.find((posting:IOtherPostings)=>posting.postingDescription===post.postingDescription);
                const sameInstrumentAccumulatedAmount=sameInstrument!==undefined?sameInstrument.accumulatedPostingAmount!:0;
                if(sameInstrument){
                    previousYearAccumulatedPostAmount+=parseFloat(sameInstrument.accumulatedPostingAmount!);
                    /*
                    if there is a match remove it from the array. this will ensure that we can get to items that might be present in current
                    year but not in previous year or vice versa.
                     */
                    lastYearPostings=_.remove(lastYearPostings,function (currentPost:IOtherPostings){return post.postingDescription!==currentPost.postingDescription});
                }
                // sheet.addRow([sameInstrumentAccumulatedAmount,post.postingDescription,'',post.accumulatedPostingAmount]);
                sheetData.push({sameInstrumentAccumulatedAmount:parseFloat(sameInstrumentAccumulatedAmount.toString()),accumulatedPostingAmount:post.accumulatedPostingAmount,postingDescription:post.postingDescription});
            }
            /*
            Now check if there are any remaining data in the array.
             */
            if(lastYearPostings.length>0){
                for(const lastYearPost of lastYearPostings){
                    // sheet.addRow([lastYearPost.accumulatedPostingAmount,lastYearPost.postingDescription,'',0]);
                    sheetData.push({accumulatedPostingAmount:lastYearPost.accumulatedPostingAmount,postingDescription:lastYearPost.postingDescription,sameInstrumentAccumulatedAmount:0});
                    previousYearAccumulatedPostAmount+=parseFloat(lastYearPost.accumulatedPostingAmount!);
                }
            }
            return {currentYearAccumulatedPostAmount,previousYearAccumulatedPostAmount,sheetData};
        }catch(error:any){
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
            throw Error(error.message);
        }

    }
    const updateUnionSettings=async ()=>{
        try{
            setStateValues({isLoading:true});
            const updateResponse=await reports.updateUnionSettings({
                applicationStartYear:getYear(new Date(state.applicationStartYear as Date)).toString(),
                startingBalance:state.startingBalance,
                unionLocation:state.unionLocation,
                unionDescription:state.unionDescription
            });
            if(updateResponse.data.status===1){
                displayMessage({
                    header: 'Union Update Success',
                    message: 'Union data was successfully updated!',
                    infoType: 'success',
                    toastComponent: toastRef,
                    life: 5000
                });
                resetStateValues();
            }

        }catch(error:any){
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }finally {
            setStateValues({isLoading:false});
        }
    }
    const controlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        inputChange(e, dispatch);
    }
    const onDialogShow=async ()=>{
        try{
            setStateValues({isLoading:true});
            const appSettings=await reports.getUnionSettings();
            const unionData=appSettings.data.operatedData;
            const unionSettings:[{applicationStartYear:string,startingBalance:string}]=JSON.parse(unionData.unionSettings!);

            setStateValues({
                unionDescription:unionData.unionDescription,
                unionLocation:unionData.unionLocation,
                startingBalance:unionSettings[0].startingBalance,
                applicationStartYear:new Date(`01-01-${unionSettings[0].applicationStartYear}`)
            });
        }catch(error:any){
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }finally {
            setStateValues({isLoading:false});
        }

    }
    const resetStateValues=()=>{
        setStateValues({
            startingBalance:'',
            unionDescription:'',
            unionLocation:''
        });
    }
    return (
        <>
            {state.isLoading && <Loader/>}
            <GeneralPageProps toastRef={toastRef}/>
            <div className="p-fluid">
                <div className="grid p-formgrid">
                    <div className="field lg:col-6 md:col-12 col-12">
                        <label htmlFor="reportYear">Report Year</label>
                        <DatePicker
                            dateValue={state.reportYear}
                            onDateChange={onReportDateChange}
                            labelText="Due Date"
                            controlId="reportYear"
                            view={'year'}
                            dateFormat='yy'
                        />
                    </div>
                    <div className="field lg:col-3 md:col-12 lg:mt-4">
                        <Button className="justify-content-center" onClick={()=>setStateValues({showDialog:true})}>Settings</Button>
                    </div>
                </div>
                <div className="grid p-formgrid">
                    <div className="field lg:col-3 md:col-12 col-6">
                        <Button className="h-10rem justify-content-center" onClick={generateExcel}>Generate Excel</Button>
                    </div>
                </div>
            </div>
            <Dialog onHide={()=>setStateValues({showDialog:false})} visible={state.showDialog}
                    header="Application Settings"
                    position="top-right"
                    onShow={onDialogShow}
                    closable
                    closeOnEscape
                    className="lg:w-7">
                <div className="p-fluid">
                    <div className="grid p-formgrid">
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="applicationStartYear">Year Began</label>
                            <DatePicker
                                dateValue={state.applicationStartYear}
                                onDateChange={(e:CalendarChangeEvent)=>setStateValues({applicationStartYear:e.value!})}
                                labelText="Organisation Begin Year"
                                controlId="applicationStartYear"
                                view={'year'}
                                dateFormat='yy'
                            />
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="startingBalance">Start Balance</label><br/>
                            <InputText type="number"
                                       value={state.startingBalance}
                                       onChange={(e) => setStateValues({startingBalance: e.target.value})}
                                       id="startingBalance" min={0}
                            />
                        </div>
                        <IconTextInput value={state.unionDescription} onInputChange={controlChange}
                                       placeholderValue="Union Description"
                                       iconText="pi pi-pencil" componentId="unionDescription"
                                       customClasses="lg:col-6 md:col-12 col-12"/>

                        <IconTextInput value={state.unionLocation} onInputChange={controlChange}
                                       placeholderValue="Union Location"
                                       iconText="pi pi-pencil" componentId="unionLocation"
                                       customClasses="lg:col-6 md:col-12 col-12"/>

                        <div className="field lg:col-4 md:col-12">
                            <Button className="justify-content-center" onClick={updateUnionSettings}>Update Union Settings</Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default ReportsInterface;
