import {IOneForAll} from "../helpers/interfaces/IOneForAll";
import {IContributions, IWithdrawalRequest} from "../helpers/typesUtils";
import {AxiosResponse} from "axios";
import {fetchAction, getBaseURL, sliceObject} from "../helpers/utils";
import {format} from "date-fns";

class WithdrawalRequest implements IOneForAll<IWithdrawalRequest>,IWithdrawalRequest{
    amountWithdrawing: string='';
    withdrawalBalance: number=0;
    withdrawalId: string='';
    memberId: string='';
    purpose: string='';
    requestDate: string | Date | Date[]='';
    async createInstance(): Promise<AxiosResponse<{status:number,operatedData:IWithdrawalRequest,error?:string}>> {
        const requestBody=this.getInstance();
        return await fetchAction('post', `${getBaseURL()}/withdrawals/new_withdrawal`, {requestBody});
    }

    async deleteInstance(deletingWithdrawalId: string): Promise<AxiosResponse<{status:number,operatedData:IWithdrawalRequest,error?:string}>> {
        return await fetchAction('post', `${getBaseURL()}/withdrawals/delete_withdrawal`, {deletingWithdrawalId});
    }

    getInstance(): Partial<IWithdrawalRequest> {
        const props:Array<keyof IWithdrawalRequest>=["withdrawalId","memberId","purpose","requestDate","amountWithdrawing"];
        return sliceObject(props,this);
    }

    makeInstance(stateValues: IWithdrawalRequest): void {
        const {withdrawalId,memberId,purpose,requestDate,amountWithdrawing}=stateValues;
        this.withdrawalId=withdrawalId;
        this.memberId=memberId;
        this.purpose=purpose;
        this.requestDate=format(new Date(requestDate.toString()),'yyyy-MM-dd');
        this.amountWithdrawing=amountWithdrawing;
    }

    async updateInstance(): Promise<AxiosResponse<{status:number,operatedData:IWithdrawalRequest,error?:string}>> {
        const requestBody=this.getInstance();
        return await fetchAction('post', `${getBaseURL()}/withdrawals/update_withdrawal`, {requestBody});
    }

    async uploadMemberRequests(contributionsList:IWithdrawalRequest[]):Promise<AxiosResponse<{status:number,operatedData:IWithdrawalRequest[]}>>{
        return await fetchAction('post', `${getBaseURL()}/withdrawals/upload_withdrawal_requests`,
            {requestBody:contributionsList});
    }
}
export default WithdrawalRequest;
