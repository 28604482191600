import {IOtherPostings, TGeneralPage, TPostingInstrument} from "../helpers/typesUtils";
import React, {SyntheticEvent, useEffect, useReducer, useRef} from "react";
import {customReducer, deleteCacheItem, displayMessage, DropdownOption, getBaseURL, getTableRowId, inputChange, pageDataValidation, REDUCER_ACTION_TYPE, remakeDropdownSelects, selectControlChange, sliceObject, updateCacheItem} from "../helpers/utils";
import {DatePicker, FilterSelect, GeneralPageProps, IconTextInput, Loader, promptUserAction, SimpleTableWithMenu, tableEditOption} from "../helpers/components";
import {Dialog} from "primereact/dialog";
import {v4 as uuidv4} from "uuid";
import {Checkbox} from "primereact/checkbox";
import {Button} from "primereact/button";
import {OverlayPanel} from "primereact/overlaypanel";
import {InputText} from "primereact/inputtext";
import {DataView} from "primereact/dataview";
import Postings from "../classes/Postings";
import {addRecordToCache, deleteCacheRecord, updateCacheRecord, useOtherPostings} from "../helpers/reactQuery";
import {useQueryClient} from "@tanstack/react-query";
import {format} from "date-fns";
import Joi from "joi";
import {DropdownChangeEvent} from "primereact/dropdown";
const _=require('lodash');

type TOtherPostings = IOtherPostings & TGeneralPage<IOtherPostings> & TPostingInstrument &
    {editingPostingInstrumentState:boolean,postingTypes:DropdownOption[]}

const INITIAL_STATE: TOtherPostings = {
    postingTypes: [],
    editingObjectId: "",
    editingState: false,
    isLoading: false,
    otherPostingId: "",
    postingDescription: "",
    postingInstruments: [],
    postingType: '',
    showAsAccumulated: true,
    showDialog: false,
    postingPeriod:new Date(),
    postingInstrumentDescription:'',
    postingInstrumentId:'',
    postingInstrumentAmount:'0',
    editingPostingInstrumentState:false,
    accumulatedPostingAmount:'0'
}

const postings=new Postings();

const validatePosting:Joi.ObjectSchema<IOtherPostings>=Joi.object({
    otherPostingId:Joi.string().required().messages({'any.required':'Posting Id is missing'}),
    postingDescription:Joi.string().required().messages(({'string.empty':'Enter a valid description current posting'})),
    postingInstruments:Joi.array().min(1).messages({'array.min':'Add at least on posting instrument to item list'}),
    postingType:Joi.string().required(),
    postingPeriod:Joi.date().messages({'date.base':"Enter a valid date"}),
    showAsAccumulated:Joi.boolean()
});

const OtherPostings = () => {
    const queryClient=useQueryClient();
    const [state, dispatch] = useReducer(customReducer<TOtherPostings>, INITIAL_STATE);
    const {data: postingsList, isLoading, refetch,dataUpdatedAt} = useOtherPostings({urlLink: `${getBaseURL()}/other_postings/get_all_postings`});
    const toastRef = useRef(null);
    const postingItemsOP=useRef<OverlayPanel>(null);
    useEffect(() => {
        const itemTypes=[{itemName:'Fixed Asset'},{itemName:'Current Asset'},
            {itemName:'Current Liability'},{itemName:'Long Term Liability'},{itemName:'Income'},
            {itemName:'Expenditure'},{itemName:'Proposed Dividend'},{itemName:'Reserves'}]
        setStateValues({
            postingTypes:remakeDropdownSelects(itemTypes,'itemName','itemName')
        });
    }, []);
    const setStateValues = (stateValues: Partial<TOtherPostings>) => {
        dispatch({
            type: REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES,
            payload: {...stateValues}
        });
    }

    if(isLoading) return <Loader/>

    const getStateValues = () => {
        const props: Array<keyof TOtherPostings> = ["otherPostingId","postingDescription","postingInstruments","postingType","showAsAccumulated","postingPeriod"];
        return sliceObject(props, state);
    }
    const postingDataValidation=()=>{
        return pageDataValidation<IOtherPostings>(validatePosting,getStateValues(),toastRef);
    }
    const otherPostingsMenu = () => {
        return [
            {
                label: 'New Posting',
                icon: 'pi pi-plus',
                command: () => setStateValues({showDialog: true})
            },
            {
                label: 'Refresh Table',
                icon: 'pi pi-refresh',
                command: () => refetch()
            },
        ]
    }
    const onPostingsDialogShow=()=>{
        if(!state.editingState){
            setStateValues({otherPostingId:uuidv4(),});
        }
    }
    const controlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        inputChange(e, dispatch);
    }
    const instrumentElementsTemplate=(element:TPostingInstrument)=>{
        return (
            <div className="col-12">
                <div className="grid p-formgrid">
                    <div className="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4">
                        <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                            <div className="flex flex-column align-items-center sm:align-items-start gap-3">
                                <div className="font-bold text-900">{element.postingInstrumentDescription}</div>
                            </div>
                            <div className="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
                                <span className="font-semibold">${element.postingInstrumentAmount}</span>
                            </div>
                            <div className="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
                                <Button id={element.postingInstrumentId} className="pi pi-pencil cursor-pointer" onClick={setupPostingElementEdit}></Button>
                            </div>
                            <div className="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
                                <Button name={element.postingInstrumentId} className="pi pi-trash cursor-pointer" onClick={deletePostingInstrument}></Button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
    const setupPostingElementEdit=(event:React.MouseEvent<HTMLButtonElement>)=>{
        try{
            const elementId=getTableRowId(event,'id');
            const selectedElement=state.postingInstruments.find((instrumentElement:TPostingInstrument)=>instrumentElement.postingInstrumentId===elementId);
            setStateValues({
                postingInstrumentId:selectedElement?.postingInstrumentId,
                postingInstrumentAmount:selectedElement?.postingInstrumentAmount,
                postingInstrumentDescription:selectedElement?.postingInstrumentDescription,
                editingPostingInstrumentState:true
            });
        }catch(error:any){
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }
    }
    const addItemPostingInstrument=(event:React.MouseEvent<HTMLButtonElement>)=>{

        const postingInstruments=[...state.postingInstruments,
            {
                otherPostingId:state.otherPostingId,
                postingInstrumentId:uuidv4(),
                postingInstrumentDescription:state.postingInstrumentDescription,
                postingInstrumentAmount:state.postingInstrumentAmount,
            }];

        setStateValues({
            postingInstruments,
            accumulatedPostingAmount:accumulatedPostingFigure(postingInstruments)
        });
    }
    const updatePostingInstrument=async ()=>{
        try{
            const beforeUpdateInstrument=state.postingInstruments.find((instrument:TPostingInstrument)=>instrument.postingInstrumentId===state.postingInstrumentId);

            const updatedInstrument={
                otherPostingId:beforeUpdateInstrument!.otherPostingId,
                postingInstrumentId: state.postingInstrumentId,
                postingInstrumentDescription: state.postingInstrumentDescription,
                postingInstrumentAmount: state.postingInstrumentAmount,
            }

            const postingInstruments=await updateCacheItem<TPostingInstrument>(updatedInstrument,beforeUpdateInstrument!,state.postingInstruments);
            setStateValues({
                postingInstruments,
                editingPostingInstrumentState:false,
                accumulatedPostingAmount:accumulatedPostingFigure(postingInstruments)
            });
        }catch(error:any){
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }
    }
    const deletePostingInstrument=async(event:React.MouseEvent<HTMLButtonElement>)=>{
        try{
            const elementId=getTableRowId(event,'name');
            const beforeUpdateInstrument=state.postingInstruments.find((instrument:TPostingInstrument)=>instrument.postingInstrumentId===elementId);
            const postingInstruments=await deleteCacheItem<TPostingInstrument>(beforeUpdateInstrument!,state.postingInstruments);
            setStateValues({
                postingInstruments,
                accumulatedPostingAmount:accumulatedPostingFigure(postingInstruments)
            });
        }catch(error:any){
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }
    }
    const promptPostingSave=(event:React.MouseEvent<HTMLButtonElement>)=>{
        if(!postingDataValidation()) return;
        promptUserAction({yesAction: savePosting, event, displayText: 'New Posting data will be added for reporting purposes. Proceed?'});
    }
    const promptPostingUpdate=(event:React.MouseEvent<HTMLButtonElement>)=>{
        if(!postingDataValidation()) return;
        promptUserAction({yesAction: updatePosting, event, displayText: 'Action will update report posting item. Proceed?'});
    }
    const savePosting=async ()=>{
        try{
            postings.makeInstance(getStateValues() as IOtherPostings);
            setStateValues({isLoading:true})
            const savedPostingResponse=await postings.createInstance();

            if(savedPostingResponse.data.status===1){
                await addRecordToCache(queryClient, ['otherPostings'], savedPostingResponse.data.operatedData);
                displayMessage({
                    header: 'Success',
                    message: 'Report posting was successfully saved',
                    infoType: 'success',
                    toastComponent: toastRef,
                    life: 5000
                });
            }
        }catch(error:any){
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }finally {
            resetStateValues();
        }
    }
    const updatePosting=async ()=>{
        try{
            postings.makeInstance(getStateValues() as IOtherPostings);

            setStateValues({isLoading:true});
            const updatedPostingResponse=await postings.updateInstance();

            if(updatedPostingResponse.data.status===1){
                await updateCacheRecord(queryClient, ['otherPostings'], [updatedPostingResponse.data.operatedData,state.editingObjectId,'otherPostingId',]);
                displayMessage({
                    header: 'Success',
                    message: 'Report posting was successfully updated',
                    infoType: 'success',
                    toastComponent: toastRef,
                    life: 5000
                });
            }
        }catch(error:any){
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }finally {
            resetStateValues();
        }
    }
    const setupOtherPostingEdit=(e:React.MouseEvent<HTMLButtonElement>)=>{
        try{
            const selectedPostingId=getTableRowId(e,'id');

            const selectedPosting=postingsList?.find((posting:IOtherPostings)=>posting.otherPostingId===selectedPostingId)!;

            const {otherPostingId,postingDescription,postingInstruments,
                postingType,showAsAccumulated,postingPeriod}=selectedPosting;
            const postingInstrumentsData=typeof postingInstruments==='string'?JSON.parse(postingInstruments):postingInstruments;
            setStateValues({
                otherPostingId,
                postingDescription,
                postingInstruments:postingInstrumentsData,
                postingPeriod:new Date(postingPeriod as Date),
                postingType,
                showAsAccumulated:showAsAccumulated === 1,
                editingState:true,
                editingObjectId:selectedPostingId,
                showDialog:true,
                accumulatedPostingAmount:accumulatedPostingFigure(postingInstrumentsData)
            });
        }catch(error:any){
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }
    }
    const accumulatedPostingFigure=(postingInstruments:TPostingInstrument[])=>{
        return postingInstruments.reduce((accumulator: any, currentValue: TPostingInstrument)=>{return accumulator+parseFloat(currentValue.postingInstrumentAmount)},0)
    }
    const deleteOtherPosting=async (event:React.MouseEvent<HTMLButtonElement>)=>{
        try{
            const deletingPostingId=getTableRowId(event,'name');
            setStateValues({isLoading:true});
            const deletedPostingResponse=await postings.deleteInstance(deletingPostingId);
            if(deletedPostingResponse.data.status===1){
                await deleteCacheRecord<IOtherPostings>(queryClient,['otherPostings'],
                    [deletedPostingResponse.data.operatedData,deletingPostingId,'otherPostingId']);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Delete Success',
                    message: 'Posting data was successfully removed!',
                    infoType: 'success',
                    life: 5000
                });
            }
        }catch(error:any){
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }finally {
            setStateValues({isLoading:false});
        }
    }
    const onDialogHide=()=>{
        if(state.editingState){
            setStateValues({
                postingDescription:'',
                postingType:'Asset',
                postingPeriod:new Date(),
                showAsAccumulated:false,
                postingInstruments:[],
                postingInstrumentDescription:'',
                postingInstrumentAmount:'0',
                showDialog: false,
                editingState: false
            });
        }else{
            setStateValues({
                showDialog: false,
                editingState: false
            });
        }
    }
    const onSelectChange = (e: DropdownChangeEvent) => {
        selectControlChange(e,dispatch);
    }
    const resetStateValues=()=>{
        setStateValues({
            editingObjectId: "",
            editingState: false,
            isLoading: false,
            otherPostingId: "",
            postingDescription: "",
            postingType: '',
            showAsAccumulated: true,
            showDialog: false,
            postingPeriod:new Date(),
            postingInstrumentDescription:'',
            postingInstrumentId:uuidv4(),
            postingInstrumentAmount:'0',
            editingPostingInstrumentState:false,
            accumulatedPostingAmount:'0'
        });
    }
    return (
        <>
            {state.isLoading && <Loader/>}
            <GeneralPageProps toastRef={toastRef}/>
            <div className="p-fluid lg:pl-5">
                <SimpleTableWithMenu
                    tableKey={"otherPostingId"}
                    columnsDef={
                        [
                            {field: 'postingDescription', header: 'Description'},
                            {body: (rowData:IOtherPostings) => <div>{format(new Date(rowData.postingPeriod as Date), 'yyyy-MM-dd')}</div>, header: 'Period'},
                            {field: 'postingType', header: 'Type'},
                            {body:(rowData)=><div>{JSON.parse(rowData.postingInstruments).reduce((accumulator: any, currentValue: { postingInstrumentAmount: any; })=>{return accumulator+parseFloat(currentValue.postingInstrumentAmount)},0)}</div> , header: 'Amount'},
                            {body: (rowData: IOtherPostings) => tableEditOption(setupOtherPostingEdit, deleteOtherPosting, rowData.otherPostingId), header: 'Edit'},
                        ]
                    }
                    tableData={postingsList}
                    menuModel={otherPostingsMenu()}
                    hasMenuList={true}
                    tableTitle="Other Report Postings"
                    lastTableUpdate={dataUpdatedAt}
                    searchValues={['postingDescription']}
                    searchFieldPlaceHolder="Search by Description"
                />
            </div>
            <Dialog onHide={onDialogHide} visible={state.showDialog}
                    header="New Posting"
                    position="top-right"
                    onShow={onPostingsDialogShow}
                    className="lg:w-7">
                <div className="p-fluid">
                    <div className="grid p-formgrid">
                        <IconTextInput value={state.postingDescription} onInputChange={controlChange}
                                       placeholderValue="Posting Description"
                                       iconText="pi pi-pencil" componentId="postingDescription"
                                       customClasses="lg:col-6 md:col-12 col-12"/>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <FilterSelect
                                selectableOptions={state.postingTypes}
                                selectedOption={state.postingType}
                                onSelectChange={onSelectChange}
                                elementId="postingType"
                                defaultValue="Posting Type"/>
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="investmentEnds">Posting Period</label>
                            <DatePicker
                                dateValue={state.postingPeriod}
                                onDateChange={(e)=>setStateValues({postingPeriod:e.value!})}
                                labelText="Year of Posting"
                                controlId="postingPeriod"
                            />
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="showAsAccumulated">Show Type</label>
                            <div>
                                <Checkbox id="showAsAccumulated" onChange={e => setStateValues({showAsAccumulated:e.checked})} checked={state.showAsAccumulated as boolean}></Checkbox>
                                <label className="ml-2">{state.showAsAccumulated?'Show Posting Elements as One Figure':'Show Each Individual posting Element'}</label>
                            </div>
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <Button onClick={(event:SyntheticEvent<HTMLButtonElement>)=>postingItemsOP.current?.show(event,event.target)}>{!state.editingState?'Click to Add Items to this Posting':'Click to Edit this Posting\'s Items'}</Button>
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <Button onClick={!state.editingState?promptPostingSave:promptPostingUpdate}>{!state.editingState?'Save Posting':'Update Posting'}</Button>
                        </div>
                        <div>Total Posting Amount:<span className="ml-3 underline text-1xl text-cyan-500">{state.accumulatedPostingAmount}</span></div>
                    </div>
                </div>
            </Dialog>
            <OverlayPanel ref={postingItemsOP} className="lg:w-4">
                <div className="grid p-formgrid">
                    <IconTextInput value={state.postingInstrumentDescription} onInputChange={controlChange}
                                   placeholderValue="Item Description"
                                   iconText="pi pi-pencil" componentId="postingInstrumentDescription"
                                   customClasses="lg:col-6 md:col-12 col-12"/>
                    <div className="field lg:col-6 md:col-12 col-12">
                        <label htmlFor="postingInstrumentAmount">Amount</label><br/>
                        <InputText type="number"
                                   value={state.postingInstrumentAmount}
                                   onChange={(e) => setStateValues({postingInstrumentAmount: e.target.value})}
                                   id="postingInstrumentAmount" min={0}
                        />
                    </div>
                    <Button className="ml-2" onClick={!state.editingPostingInstrumentState?addItemPostingInstrument:updatePostingInstrument}>{!state.editingPostingInstrumentState?'Add Element':'Update Element'}</Button>
                </div>
                <div className="card mt-2">
                    <h6>Items List</h6>
                    <DataView value={state.postingInstruments} itemTemplate={instrumentElementsTemplate}/>
                </div>
            </OverlayPanel>
        </>
    )
}
export default OtherPostings;
