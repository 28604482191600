import {ILoans, IPayments, TGeneralPage, TScannedFiles} from "../helpers/typesUtils";
import React, {ChangeEvent, useEffect, useReducer, useRef} from "react";
import {customReducer, deleteCacheItem, displayMessage, downloadExcelFile, DropdownOption, encodeFilesToData, getBaseURL, getTableRowId, pageDataValidation, REDUCER_ACTION_TYPE, remakeDropdownSelects, selectControlChange, sliceObject} from "../helpers/utils";
import {Toast} from "primereact/toast";
import {useQueryClient} from "@tanstack/react-query";
import {DropdownChangeEvent} from "primereact/dropdown";
import {addRecordToCache, deleteCacheRecord, updateCacheRecord, useLoansListFetch, useMembershipListFetch} from "../helpers/reactQuery";
import FileUploader, {DatePicker, FilterSelect, GeneralPageProps, Loader, promptUserAction, SimpleTableWithMenu, tableEditOption} from "../helpers/components";
import {format, differenceInMonths, addMonths} from "date-fns";
import {v4 as uuidv4} from "uuid";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import Loans from "../classes/Loans";
import {CalendarChangeEvent} from "primereact/calendar";
import Joi from "joi";
import {OverlayPanel} from "primereact/overlaypanel";
import {DataView} from "primereact/dataview";
import {Divider} from "primereact/divider";
import {FileUploadHandlerEvent} from "primereact/fileupload";

type TLoans = ILoans & IPayments & TGeneralPage<ILoans> & {
    members: DropdownOption[], fullName?: string, sapNumber?: string, coyNumber?: string, loanPaymentsList: IPayments[], paymentEditState: boolean
}
const INITIAL_STATE: TLoans = {
    allocatedMonths: "0",
    deductionStartDate: new Date(),
    applicationDate: new Date(),
    dueDate: new Date(),
    editingObjectId: "",
    editingState: false,
    interestRate: '0',
    isLoading: false,
    loanApplicationId: "",
    memberId: "",
    monthlyDeductible: '0',
    principalAmount: '0',
    showDialog: false,
    members: [],
    totalPayable: '0',
    paymentId: '',
    paidAmount: '0',
    paidDate: new Date(),
    loanPaymentsList: [],
    paymentEditState: false,
    scannedFiles: [],
    scannedFileNames: []
}
const loans = new Loans();

const validateLoan: Joi.ObjectSchema<ILoans> = Joi.object({
    loanApplicationId: Joi.string().required(),
    memberId: Joi.string().required().messages({'string.empty': 'Select member applying  loan'}),
    principalAmount: Joi.number().min(1).messages({'number.min': 'Ensure that principal amount is valid and greater 0'}),
    interestRate: Joi.number().min(0).messages({'number.min': 'Interest cannot be less than 0'}),
    applicationDate: Joi.date().required().messages({'any.required': 'Select a valid date value for application date!'}),
    dueDate: Joi.date().required().messages({'any.required': 'Select a valid date value for due date!'}),
    monthlyDeductible: Joi.number().required(),
    allocatedMonths: Joi.number().min(1).required().messages({'number.min': 'Ensure that loan due date is greater than deduction start date!'}),
    scannedFileNames: Joi.array().optional(),
    scannedFiles: Joi.array(),
    deductionStartDate: Joi.date().required().messages({'date.base': 'Input correct date value for deduction start date!'})
});
const validateLoanPayment: Joi.ObjectSchema<IPayments> = Joi.object({
    paymentId: Joi.string().required().messages({'any.required': 'Payment ID is required'}),
    paidAmount: Joi.number().min(1).required().messages({
        'any.required': 'Paid Amount is required',
        'number.min': 'Payment Amount should be greater than 0'
    }),
    paidDate: Joi.date().required().messages({'any.required': 'Select a valid date to continue payment'})
});
const LoanApplication = () => {
    const queryClient = useQueryClient();
    const [state, dispatch] = useReducer(customReducer<TLoans>, INITIAL_STATE);
    const toastRef = useRef<Toast>(null);
    const paymentsOP = useRef<OverlayPanel>(null);
    const scannedFileOPRef = useRef<OverlayPanel>(null);
    const scannedFileNamesRef = useRef<OverlayPanel>(null);
    const uploadScannedFilesRef = useRef(null);

    const {data: membersList, isLoading, refetch} = useMembershipListFetch({urlLink: `${getBaseURL()}/membership/get_all_members`});

    const {data: loansList, isLoading: loansLoading, refetch: fetchLoans, dataUpdatedAt} = useLoansListFetch({urlLink: `${getBaseURL()}/loans/get_loans_list`});

    useEffect(() => {
        if (membersList) {
            setStateValues({
                members: remakeDropdownSelects(membersList, 'fullName', 'memberId'),
            });
        }
    }, []);
    const setStateValues = (stateValues: Partial<TLoans>) => {
        dispatch({
            type: REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES,
            payload: {...stateValues}
        });
    }
    if(loansLoading || isLoading) return <Loader/>

    const getStateValues = () => {
        const props: Array<keyof ILoans> = ["loanApplicationId", "memberId", "principalAmount", "deductionStartDate",
            "interestRate", "applicationDate", "dueDate", "monthlyDeductible", "allocatedMonths", "scannedFiles", "scannedFileNames"];
        return sliceObject(props, state);
    }
    const isLoanDataValid = (): boolean => {
        return pageDataValidation<ILoans>(validateLoan, getStateValues(), toastRef);
    }
    const isLoanPaymentValid = (): boolean => {
        return pageDataValidation<IPayments>(validateLoanPayment,
            {paymentId: state.paymentId, paidAmount: state.paidAmount, paidDate: state.paidDate}, toastRef);
    }
    const onSelectChange = (e: DropdownChangeEvent) => {
        selectControlChange(e, dispatch);
    }
    const onInterestValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        const interestValue = e.target.value === '' ? 0 : parseFloat(e.target.value);
        const loanMonths = differenceInMonths(new Date(state.dueDate as Date), new Date(state.applicationDate as Date));
        const payable = parseFloat(state.principalAmount) * (interestValue / 100) + parseFloat(state.principalAmount);
        const monthlyPayable = loanMonths > 0 ? (payable / loanMonths).toFixed(2) : payable;
        setStateValues({interestRate: e.target.value, totalPayable: payable.toString(), monthlyDeductible: monthlyPayable.toString()})
    }
    const onDueDateChange = (e: CalendarChangeEvent) => {
        const loanMonths = calculateLoanMonths(new Date(state.deductionStartDate as Date), new Date(e.value as Date));

        const monthlyDeduction = calculateMonthlyDeductions(loanMonths);

        setStateValues({
            dueDate: e.value!,
            monthlyDeductible: monthlyDeduction.toString(),
            allocatedMonths: loanMonths.toString()
        });

    }
    const onDeductionStartDateChange = (e: CalendarChangeEvent) => {
        const loanMonths = calculateLoanMonths(new Date(e.value as Date), state.dueDate as Date).toString();
        if (parseInt(loanMonths) > 0) {
            setStateValues({
                deductionStartDate: e.value!,
                allocatedMonths: loanMonths,
                monthlyDeductible: calculateMonthlyDeductions(parseInt(loanMonths)).toString()
            })
        } else {
            setStateValues({
                deductionStartDate: e.value!,
                allocatedMonths: '0',
                monthlyDeductible: '0'
            });
        }
    }
    const calculateLoanMonths = (startDate: Date, endDate: Date) => {
        return differenceInMonths(endDate, startDate);
    }
    const calculateMonthlyDeductions = (loanMonths: number) => {
        return parseFloat(state.totalPayable) > 0 && loanMonths > 0 ? parseFloat(state.totalPayable) / loanMonths : state.totalPayable;

    }
    const onPrincipalAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
        const principalValue = e.target.value === '' ? 0 : parseFloat(e.target.value);
        const interestValue = state.interestRate !== '' ? parseFloat(state.interestRate) / 100 : 0;
        const loanMonths = differenceInMonths(new Date(state.dueDate as Date), new Date(state.applicationDate as Date));
        const payable = interestValue > 0 ? (principalValue * interestValue) + principalValue : 0;
        const monthlyPayable = interestValue > 0 && loanMonths > 0 ? (payable / loanMonths).toFixed(2) : payable;
        setStateValues({
            principalAmount: e.target.value,
            totalPayable: payable.toString(),
            monthlyDeductible: monthlyPayable.toString(),
            allocatedMonths: loanMonths.toString()
        });
    }
    const loansMenu = () => {
        return [
            {
                label: 'New Application',
                icon: 'pi pi-plus',
                command: () => setStateValues({showDialog: true})
            },
            {
                label: 'Download Loans',
                icon: 'pi pi-download',
                command: () => downloadLoansList()
            },
            {
                label: 'Refresh Table',
                icon: 'pi pi-refresh',
                command: () => fetchLoans()
            },
        ]
    }
    const confirmLoanApplication = async () => {
        try{
            loans.makeInstance(getStateValues() as ILoans);
            setStateValues({isLoading:true});
            const newLoanResponse = await loans.createInstance();
            if (newLoanResponse.data.status === 1) {
                await addRecordToCache(queryClient, ['loansList'], newLoanResponse.data.operatedData);
                displayMessage({
                    header: 'Application Success',
                    message: 'Loan application was successfully created!',
                    infoType: 'success',
                    toastComponent: toastRef,
                    life: 5000
                });
            }
        }catch (error:any){
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }finally {
            resetStateValues();
        }
    }
    const promptLoanUpdate = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!isLoanDataValid()) return;
        promptUserAction({yesAction: updateLoanApplication, event, displayText: 'Are you sure you want to update selected application?'});
    }
    const promptLoanSave = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!isLoanDataValid()) return;
        promptUserAction({yesAction: confirmLoanApplication, event, displayText: 'Are you sure you want to save loan application?'});
    }
    const updateLoanApplication = async () => {
        try {
            loans.makeInstance(getStateValues() as ILoans);
            setStateValues({isLoading:true});
            const updateLoanResponse = await loans.updateInstance();
            if (updateLoanResponse.data.status === 1) {
                await updateCacheRecord(queryClient, ['loansList'], [updateLoanResponse.data.operatedData, state.editingObjectId, 'loanApplicationId']);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Update Success',
                    message: 'Selected Loan was successfully updated with the supplied credentials',
                    infoType: 'success',
                    life: 3000
                });
                resetStateValues();
            }
        } catch (error: any) {
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }finally {
            resetStateValues();
        }
    }
    const onLoanDialogShow = () => {
        if (!state.editingState) {
            setStateValues({
                loanApplicationId: uuidv4()
            });
        }
    }
    const setupLoanEdit = (event: React.MouseEvent<HTMLButtonElement>) => {
        const clickedLoanId = getTableRowId(event, 'id');

        const selectedLoan = loansList?.find((loan: ILoans) => loan.loanApplicationId === clickedLoanId);
        if (selectedLoan) {
            const {
                loanApplicationId, memberId, principalAmount, interestRate, scannedFileNames, deductionStartDate,
                totalPayable, applicationDate, dueDate, monthlyDeductible,
            } = selectedLoan;
            setStateValues({
                loanApplicationId,
                memberId,
                principalAmount,
                interestRate,
                totalPayable: (parseFloat(interestRate) / 100 * parseFloat(principalAmount) + parseFloat(principalAmount)).toFixed(2),
                applicationDate: new Date(applicationDate as Date),
                dueDate: new Date(dueDate as Date),
                scannedFileNames: typeof scannedFileNames === 'string' ? JSON.parse(scannedFileNames) : scannedFileNames,
                deductionStartDate: new Date(deductionStartDate as Date),
                allocatedMonths: calculateLoanMonths(new Date(deductionStartDate as Date), new Date(dueDate as Date)).toString(),
                monthlyDeductible,
                editingObjectId: clickedLoanId,
                editingState: true,
                showDialog: true
            });
        }
    }
    const promptLoanDelete = async (event: React.MouseEvent<HTMLButtonElement>) => {
        try {
            const deletableLoanId = getTableRowId(event, 'name');
            setStateValues({isLoading:true});
            const deletableLoanResponse = await loans.deleteInstance(deletableLoanId);
            if (deletableLoanResponse.data.status === 1) {
                await deleteCacheRecord<ILoans>(queryClient, ['loansList'], [deletableLoanResponse.data.operatedData, deletableLoanId, 'loanApplicationId']);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Delete Success',
                    message: 'Loan Application was successfully deleted!',
                    infoType: 'success',
                    life: 5000
                });
            }
        } catch (error: any) {
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }finally {
            setStateValues({
                isLoading:false
            });
        }
    }

    const downloadLoansList = () => {
        const iList = loansList?.map((loan: Partial<TLoans>) => {
            const {
                sapNumber, coyNumber, fullName,
                principalAmount, interestRate,
                applicationDate, dueDate,
                monthlyDeductible,
            } = loan;
            return {
                sapNumber,
                coyNumber,
                fullName,
                applicationDate: format(new Date(applicationDate as Date), 'yyyy-MM-dd'),
                dueDate: format(new Date(dueDate as Date), 'yyyy-MM-dd'),
                principalAmount,
                interestRate,
                interestPayable: (parseFloat(interestRate!) / 100) * parseFloat(principalAmount!),
                totalOwe: ((parseFloat(interestRate!) / 100) * parseFloat(principalAmount!)) + parseFloat(principalAmount!),
                monthlyDeductible,
            }
        })
        try {
            downloadExcelFile(iList, 'loans_list', [
                {
                    sapNumber: 'SAP NUMBER',
                    coyNumber: 'COY NUMBER',
                    fullName: 'FULL NAME',
                    applicationDate: 'DATE APPLIED',
                    dueDate: 'DUE DATE',
                    principalAmount: 'PRINCIPAL AMOUNT',
                    interestRate: 'INTEREST RATE',
                    interestPayable: 'INTEREST AMOUNT',
                    totalOwe: 'TOTAL OWE',
                    monthlyDeductible: 'MONTHLY PAYMENT'
                }
            ])
        } catch (error:any) {
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }
    }
    const onPaymentButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {

        const applicationId = getTableRowId(event, 'id');
        const selectedApplication = loansList?.find((loan: ILoans) => loan.loanApplicationId === applicationId);
        const applicationPayments = typeof selectedApplication?.pays === 'string' ? JSON.parse(`[${selectedApplication?.pays}]`) : [];

        setStateValues({
            loanApplicationId: applicationId,
            loanPaymentsList: applicationPayments,
            paymentEditState: false,
            paidAmount: '0',
            paidDate: new Date()
        });

        paymentsOP.current?.toggle(event);
    }
    const promptNewLoanPayment = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!isLoanPaymentValid()) return;
        promptUserAction({yesAction: () => onAcceptPayment(event), event, displayText: 'Are you sure to add new payment for selected loan?'});
    }
    const promptUpdateLoanPayment = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!isLoanPaymentValid()) return;
        promptUserAction({yesAction: () => updatePayment(event), event, displayText: 'Are you sure you want to update selected loan payment?'});
    }
    const onAcceptPayment = async (event: React.MouseEvent<HTMLButtonElement>) => {
        try {
            //TODO:ADD CHECK TO SEE IF PAYMENT AMOUNT IS NOT GREATER THAN OWING AMOUNT
            const loanData: Partial<TLoans> = {
                paymentId: uuidv4(),
                loanApplicationId: state.loanApplicationId, paidAmount: state.paidAmount,
                paidDate: format(new Date(state.paidDate.toString()), 'yyyy-MM-dd')
            };
            setStateValues({isLoading: true});
            const paymentResponse = await loans.acceptLoanPayment(loanData as ILoans);
            if (paymentResponse.data.status === 1) {
                await updateCacheRecord(queryClient, ['loansList'], [paymentResponse.data.operatedData, state.loanApplicationId, 'loanApplicationId']);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Payment Success',
                    message: 'Payment was successfully made against selected Loan',
                    infoType: 'success',
                    life: 3000
                });
            }
            paymentsOP.current?.toggle(event);
        } catch (error: any) {
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        } finally {
            resetStateValues();
        }
    }
    const setupPaymentEdit = async (event: React.MouseEvent<HTMLButtonElement>) => {
        const paymentId = getTableRowId(event, 'id');
        const selectedPayment = state.loanPaymentsList.find((payment: IPayments) => payment.paymentId === paymentId);
        setStateValues({
            paymentId,
            paidAmount: selectedPayment?.paidAmount,
            paidDate: new Date(selectedPayment?.paidDate as Date),
            paymentEditState: true
        });
    }
    const updatePayment = async (event: React.MouseEvent<HTMLButtonElement>) => {
        //TODO:ADD CHECK TO SEE IF PAYMENT AMOUNT IS NOT GREATER THAN OWING AMOUNT
        try {
            const loanData: Partial<TLoans> = {
                paymentId: state.paymentId,
                loanApplicationId: state.loanApplicationId, paidAmount: state.paidAmount,
                paidDate: format(new Date(state.paidDate.toString()), 'yyyy-MM-dd')
            };
            setStateValues({isLoading: true});
            const updatePaymentResponse = await loans.updateLoanPayment(loanData as ILoans);

            if (updatePaymentResponse.data.status === 1) {
                await updateCacheRecord(queryClient, ['loansList'], [updatePaymentResponse.data.operatedData, state.loanApplicationId, 'loanApplicationId']);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Payment Success',
                    message: 'Payment was successfully updated against selected Loan',
                    infoType: 'success',
                    life: 3000
                });
            }
            paymentsOP.current?.toggle(event);
        } catch (error: any) {
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        } finally {
            resetStateValues();
        }
    }
    const paymentsListDisplayTemplate = (payment: IPayments) => {
        return (
            <>
                <div className="col-12">
                    <div className="flex flex-column xl:flex-row xl:align-items-start">
                        <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1">
                            <span>{payment.paidDate?.toString()}</span>
                            <span>{payment.paidAmount?.toString()}</span>
                            {payment.paidDate && <span><Button id={payment.paymentId} onClick={setupPaymentEdit}>Edit</Button></span>}
                        </div>
                    </div>
                </div>
                <Divider/>
            </>
        )
    }
    const scannedFileNamesTemplate = (scannedFile: string) => {
        return (
            <>
                <div className="col-12">
                    <div className="flex flex-column xl:flex-row xl:align-items-start">
                        <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1">
                            <a href={`${getBaseURL()}/scannedFiles/${scannedFile}`} target="_blank">{scannedFile}</a>
                        </div>
                    </div>
                </div>
                <Divider/>
            </>
        )
    }
    const onLoanMonthsChange = (e: ChangeEvent<HTMLInputElement>) => {
        const userMonths = parseInt(e.target.value);
        const newDueDate = addMonths(new Date(state.deductionStartDate as Date), userMonths);
        setStateValues({allocatedMonths: e.target.value!, dueDate: newDueDate, monthlyDeductible: userMonths > 0 ? (parseFloat(state.totalPayable) / userMonths).toString() : '0'});

    }
    const setScannedFilesUpload = async (event: FileUploadHandlerEvent) => {
        try{
            const fileItems: TScannedFiles[] = [...state.scannedFiles];
            for (let i = 0; i < event.files.length; i++) {
                fileItems.push(await encodeFilesToData(event.files[i]));
            }
            setStateValues({
                scannedFiles: fileItems,
                scannedFileNames: fileItems.map((item: TScannedFiles) => item.fName)
            });
        }catch(error:any){
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }
    }
    const openApplicationFiles = (event: React.MouseEvent<HTMLButtonElement>) => {
        const applicationId = getTableRowId(event, 'id');
        const selectedApplication = loansList?.find((loan: ILoans) => loan.loanApplicationId === applicationId);
        const applicationFiles = typeof selectedApplication?.scannedFileNames === 'string' ? JSON.parse(selectedApplication?.scannedFileNames) : selectedApplication?.scannedFileNames;
        setStateValues({
            scannedFileNames: applicationFiles
        });
        scannedFileNamesRef.current?.toggle(event);
    }
    const resetStateValues = () => {
        setStateValues({
            allocatedMonths: '',
            deductionStartDate: new Date(),
            memberId: '',
            principalAmount: '',
            interestRate: '',
            totalPayable: '',
            applicationDate: new Date(),
            dueDate: new Date(),
            monthlyDeductible: '',
            paymentId:uuidv4(),
            paidDate:new Date(),
            paidAmount:'',
            scannedFileNames:[],
            scannedFiles:[],
            showDialog:false,
            editingState:false,
            isLoading:false,
        });
    }
    return (
        <>
            {state.isLoading && <Loader/>}
            <GeneralPageProps toastRef={toastRef}/>
            <div className="p-fluid lg:pl-5">
                <SimpleTableWithMenu
                    tableKey={"loanApplicationId"}
                    columnsDef={
                        [
                            {field: 'sapNumber', header: 'SAP #'},
                            {field: 'fullName', header: 'Name'},
                            {body: (rowData) => <div>{format(new Date(rowData.applicationDate.toString()), 'yyyy-MM-dd')}</div>, header: 'Date'},
                            {body: (rowData) => <div>{format(new Date(rowData.dueDate.toString()), 'yyyy-MM-dd')}</div>, header: 'Due'},
                            {field: 'principalAmount', header: 'Principal'},
                            {body: (rowData) => <div>{(rowData.interestRate / 100) * rowData.principalAmount}</div>, header: 'Interest'},
                            {body: (rowData) => <div>{((rowData.interestRate / 100) * rowData.principalAmount) + rowData.principalAmount}</div>, header: 'Total Owe'},
                            {field: 'totalPaid', header: 'Total Paid'},
                            {body: (rowData) => <div>{(((rowData.interestRate / 100) * rowData.principalAmount) + rowData.principalAmount) - rowData.totalPaid}</div>, header: 'Bal'},
                            {body: (rowData) => <div><Button id={rowData.loanApplicationId} icon="pi pi-file" className="p-button-rounded p-button-primary" onClick={openApplicationFiles}/></div>, header: 'Files'},
                            {body: (rowData: ILoans) => <div><Button id={rowData.loanApplicationId} icon="pi pi-external-link" className="p-button-rounded p-button-info" onClick={onPaymentButtonClick}/></div>, header: 'Payments'},
                            {body: (rowData: ILoans) => tableEditOption(setupLoanEdit, promptLoanDelete, rowData.loanApplicationId), header: 'Edit'},
                        ]
                    }
                    tableData={loansList}
                    menuModel={loansMenu()}
                    hasMenuList={true}
                    tableTitle="Loan Applications"
                    lastTableUpdate={dataUpdatedAt}
                    searchValues={['sapNumber', 'coyNumber', 'fullName']}
                    searchFieldPlaceHolder="Search by Full Name, COY Number, SAP Number"
                />
            </div>
            <Dialog onHide={() => setStateValues({showDialog: false, editingState: false})} visible={state.showDialog}
                    header="New Loan Application"
                    position="top-right"
                    onShow={onLoanDialogShow}
                    className="lg:w-7">
                <div className="p-fluid">
                    <div className="grid p-formgrid">
                        <div className="field lg:col-6 md:col-12 col-12">
                            <FilterSelect
                                selectableOptions={state.members}
                                selectedOption={state.memberId}
                                onSelectChange={onSelectChange}
                                elementId="memberId"
                                defaultValue="Members List"/>
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="principalAmount">Principal Amount</label>
                            <InputText type="number"
                                       value={state.principalAmount}
                                       onChange={onPrincipalAmountChange}
                                       id="principalAmount" min={0}
                            />
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="interestRate">Interest Rate</label>
                            <InputText type="number"
                                       value={state.interestRate}
                                       onChange={onInterestValueChange}
                                       id="interestRate" min={0}
                            />
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="totalPayable">Total Payable</label>
                            <InputText type="number"
                                       value={state.totalPayable}
                                       onChange={(e) => setStateValues({totalPayable: e.target.value!})}
                                       id="totalPayable" disabled={true} min={0}
                            />
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="applicationDate">Application Date</label>
                            <DatePicker
                                dateValue={state.applicationDate}
                                onDateChange={(e) => setStateValues({applicationDate: e.value!})}
                                labelText="Application Date"
                                controlId="applicationDate"
                                maximumDateValue={new Date()}
                            />
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="deductionStartDate">Deduction Start Date</label>
                            <DatePicker
                                dateValue={state.deductionStartDate}
                                onDateChange={onDeductionStartDateChange}
                                labelText="Deducation Start Date"
                                controlId="deductionStartDate"
                                minimumDateValue={state.applicationDate as Date}
                            />
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="dueDate">Due Date</label>
                            <DatePicker
                                dateValue={state.dueDate}
                                onDateChange={onDueDateChange}
                                labelText="Due Date"
                                controlId="dueDate"
                                minimumDateValue={state.deductionStartDate as Date}
                            />
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="allocatedMonths">Allocated Months</label>
                            <InputText type="number"
                                       value={state.allocatedMonths}
                                       onChange={onLoanMonthsChange}
                                       id="allocatedMonths" min={0}
                            />
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="monthlyDeductible">Monthly Deductible</label>
                            <InputText type="number"
                                       value={state.monthlyDeductible}
                                       onChange={(e) => setStateValues({monthlyDeductible: e.target.value!})}
                                       id="monthlyDeductible" disabled min={0}
                            />
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12 mt-4">
                            <Button onClick={(e) => scannedFileOPRef.current?.toggle(e)}>Add Application Scanned Letter</Button>
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12 mt-4">
                            <Button onClick={!state.editingState ? promptLoanSave : promptLoanUpdate}>{!state.editingState ? 'Confirm Application' : 'Update Application'}</Button>
                        </div>
                    </div>
                </div>
                <i>Number of months required to pay loan: {state.allocatedMonths}</i>
            </Dialog>
            <OverlayPanel ref={paymentsOP} className="lg:w-5">
                <div className="p-fluid">
                    <div className="grid p-formgrid">
                        <div className="field lg:col-4 md:col-12 col-12">
                            <label htmlFor="paidAmount">Amount Paying</label>
                            <InputText type="number"
                                       value={state.paidAmount}
                                       onChange={(e) => setStateValues({paidAmount: e.target.value!})}
                                       id="paidAmount" min={0}
                            />
                        </div>
                        <div className="field lg:col-4 md:col-12 col-12">
                            <label htmlFor="dueDate">Due Date</label>
                            <DatePicker
                                dateValue={state.paidDate}
                                onDateChange={(e) => setStateValues({paidDate: e.value!})}
                                labelText="Due Date"
                                controlId="paidDate"
                                maximumDateValue={new Date()}
                            />
                        </div>
                        <div className="field lg:col-4 md:col-12 col-12 mt-4">
                            <Button onClick={!state.paymentEditState ? promptNewLoanPayment : promptUpdateLoanPayment}>{!state.paymentEditState ? `Accept Payment` : `Update Payment`}</Button>
                        </div>
                    </div>
                </div>
                <h6>Payments List</h6>
                <Divider/>
                <DataView value={state.loanPaymentsList} emptyMessage="Nothing Found" itemTemplate={paymentsListDisplayTemplate} paginator rows={3}/>
            </OverlayPanel>
            <OverlayPanel ref={scannedFileOPRef}>
                <FileUploader onFileUpload={setScannedFilesUpload} fileUploadRef={uploadScannedFilesRef} id="applicationFiles" acceptableFileTypes=".pdf"/>
            </OverlayPanel>
            <OverlayPanel ref={scannedFileNamesRef}>
                <DataView value={state.scannedFileNames} emptyMessage="Nothing Found" itemTemplate={scannedFileNamesTemplate} paginator rows={3}/>
            </OverlayPanel>
        </>
    )
}
export default LoanApplication;
