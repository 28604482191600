import {IOneForAll} from "../helpers/interfaces/IOneForAll";
import {IOtherPostings, TPostingInstrument} from "../helpers/typesUtils";
import {AxiosResponse} from "axios";
import {fetchAction, getBaseURL, sliceObject} from "../helpers/utils";
import {format} from "date-fns";

class Postings implements IOneForAll<IOtherPostings>,IOtherPostings{

    otherPostingId: string='';
    postingDescription: string='';
    postingInstruments: TPostingInstrument[]=[];
    postingPeriod: string | Date | Date[]=new Date();
    postingType: string='';
    showAsAccumulated: boolean|number=false;
    async createInstance(): Promise<AxiosResponse<{status:number,operatedData:IOtherPostings,error?:string}>> {
        const requestBody=this.getInstance();
        console.log(requestBody);
        return await fetchAction('post', `${getBaseURL()}/other_postings/new_other_posting`, {requestBody});
    }

    async deleteInstance(deletingOtherPostId: string): Promise<AxiosResponse<{status:number,operatedData:IOtherPostings,error?:string}>> {
        return await fetchAction('delete', `${getBaseURL()}/other_postings/delete_other_posting`, {deletingOtherPostId});
    }

    getInstance(): Partial<IOtherPostings> {
        const props:Array<keyof IOtherPostings>=["otherPostingId","postingDescription","postingInstruments",
            "postingType","showAsAccumulated","postingPeriod"];
        return sliceObject(props,this);
    }

    makeInstance(stateValues: IOtherPostings): void {
        const {otherPostingId,postingDescription,
            postingInstruments,postingType,
            showAsAccumulated,postingPeriod}=stateValues;
        this.otherPostingId=otherPostingId;
        this.postingDescription=postingDescription;
        this.postingInstruments=postingInstruments;
        this.postingType=postingType;
        this.showAsAccumulated=showAsAccumulated===true?1:0;
        this.postingPeriod=format(new Date(postingPeriod as Date),'yyyy-MM-dd');
    }

    async updateInstance(): Promise<AxiosResponse<{status:number,operatedData:IOtherPostings,error?:string}>> {
        const requestBody=this.getInstance();
        return await fetchAction('patch', `${getBaseURL()}/other_postings/update_other_posting`, {requestBody});
    }
}
export default Postings;
