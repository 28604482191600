import {QueryClient, QueryKey, useQuery} from "@tanstack/react-query";
import {IContributions, IInvestmentInstruments, ILoans, IMember, IOtherPostings, IWithdrawalRequest, TFetchListArg} from "./typesUtils";
import {addNewItemToCache, deleteCacheItem, fetchPageInit, updateCacheItem} from "./utils";

export const useMembershipListFetch = (reqParams: TFetchListArg) => useQuery(['membersList'],
    async () => await fetchPageInit<IMember[]>(reqParams), {
        select: (data) => {
            return data;
        }
    });
export const useContributionsListFetch = (reqParams: TFetchListArg) => useQuery(['contributionsList'],
    async () => await fetchPageInit<IContributions[]>(reqParams));
export const useWithdrawalsListFetch = (reqParams: TFetchListArg) => useQuery(['withdrawalsList'],
    async () => await fetchPageInit<IWithdrawalRequest[]>(reqParams));
export const useLoansListFetch = (reqParams: TFetchListArg) => useQuery(['loansList'],
    async () => await fetchPageInit<ILoans[]>(reqParams), {
        select: (data) => {
            return data;
        }
    });
export const useInvestmentInstruments = (reqParams: TFetchListArg) => useQuery(['investmentInstruments'],
    async () => await fetchPageInit<IInvestmentInstruments[]>(reqParams));
export const useOtherPostings = (reqParams: TFetchListArg) => useQuery(['otherPostings'],
    async () => await fetchPageInit<IOtherPostings[]>(reqParams));
export async function addRecordToCache<T>(queryClient: QueryClient, keyInCache: QueryKey,newItem:T) {
    try {
        const existingArray = queryClient.getQueryData<T[]>(keyInCache) || [];
        const updatedArray = await addNewItemToCache(existingArray, newItem);

        // Update the cache with the updated array
        queryClient.setQueryData(keyInCache, updatedArray);
    } catch (error) {
        console.log(error);
    }
}
export async function addRecordsToCache<T>(queryClient: QueryClient, keyInCache: QueryKey,newItems:T[]) {
    try {
        const existingArray = queryClient.getQueryData<T[]>(keyInCache) || [];

        // Update the cache with the updated array
        queryClient.setQueryData(keyInCache, newItems);
    } catch (error) {
        console.log(error);
    }
}
export async function updateCacheRecord<T>(queryClient: QueryClient, keyInCache: QueryKey,data:[T,string,string]) {
    try {
        const updatedItem = data[0]

        const itemId = data[1] as string;

        const itemIdProp = data[2] as string;

        const existingArray = queryClient.getQueryData<T[]>(keyInCache) || [];
        const selectedRecord = existingArray?.find((item: any) => item[itemIdProp] === itemId);

        if (selectedRecord) {
            const updatedArray = await updateCacheItem<T | string>(updatedItem, selectedRecord!, existingArray);

            // Update the cache with the updated array
            queryClient.setQueryData(keyInCache, [...updatedArray]);
        }
    } catch (error: any) {
        console.log(error);
    }
}

export async function deleteCacheRecord<T>(queryClient: QueryClient, keyInCache: QueryKey,data:[T,string,string]) {
    try {

        const deletingItem = data[0]

        const itemId = data[1] as string;

        const itemIdProp = data[2] as string;

        const existingArray = queryClient.getQueryData<T[]>(keyInCache) || [];

        const selectedRecord=existingArray.find((rec:any)=>rec[itemIdProp]===itemId);

        const remainingData = await deleteCacheItem(selectedRecord, existingArray);

        // Update the cache with the updated array
        queryClient.setQueryData(keyInCache, remainingData);
    } catch (error) {
        console.log(error);
    }
}
