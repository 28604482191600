import FileUploader, {DatePicker, DefaultSpinner, FilterSelect, GeneralPageProps, Loader, promptUserAction, SimpleTableWithMenu, tableEditOption} from "../helpers/components";
import React, {useEffect, useReducer, useRef} from "react";
import {Toast} from "primereact/toast";
import {IContributions, IMember, TGeneralPage} from "../helpers/typesUtils";
import {configureExcelUpload, customReducer, displayMessage, downloadExcelFile, DropdownOption, getBaseURL, getTableRowId, pageDataValidation, REDUCER_ACTION_TYPE, remakeDropdownSelects, selectControlChange, sliceObject} from "../helpers/utils";
import {Dialog} from "primereact/dialog";
import {v4 as uuidv4} from "uuid";
import {addRecordsToCache, addRecordToCache, deleteCacheRecord, updateCacheRecord, useContributionsListFetch, useMembershipListFetch} from "../helpers/reactQuery";
import {DropdownChangeEvent} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import ContributionsClass from "../classes/Contributions";
import {addDays, format, isAfter, isBefore, subDays} from "date-fns";
import {useQueryClient} from "@tanstack/react-query";
import {FileUploadHandlerEvent} from "primereact/fileupload";
import Joi from "joi";
import {OverlayPanel} from "primereact/overlaypanel";
import {Checkbox, CheckboxChangeEvent} from "primereact/checkbox";
import contributions from "../classes/Contributions";

type TContributions=IContributions & TGeneralPage<IContributions> & Partial<IMember> &
    {members:DropdownOption[],wageTypes:DropdownOption[],showUploaderDialog:boolean,
        wageTypeDesc?:string,fullName?:string,showContributionsDialog:boolean,
        contributionDownloadDates:Date[],filteredContributionMemberId:string,downloadMemberOnly:boolean};
const INITIAL_STATE:TContributions={
    amountContributed: '0',
    contributionDate: new Date(),
    contributionId: "",
    editingObjectId: "",
    editingState: false,
    isLoading: false,
    memberId: "",
    showDialog: false,
    wageTypeId: 0,
    members:[],
    wageTypes:[],
    showUploaderDialog:false,
    showContributionsDialog:false,
    contributionDownloadDates:[new Date(),new Date()],
    filteredContributionMemberId:'',
    downloadMemberOnly:false
}

const validateContribution:Joi.ObjectSchema<IContributions>=Joi.object({
    contributionId:Joi.string().messages({'string.empty':'Contribution Id was not found'}),
    memberId:Joi.string().messages({'string.empty':'Select member who is making contribution'}),
    wageTypeId:Joi.number().min(1).messages({'number.base':'Select a wage Type','number.min':'Select a wage Type'}),
    amountContributed:Joi.number().min(1).messages({'number.base':'Input a valid number for contribution',
        'number.min':'Contribution amount should be greater than 0'}),
    contributionDate:Joi.date().messages({'date.base':'Select a valid date for contribution'})
});

const contribution=new ContributionsClass();


const Contributions=()=>{
    const queryClient = useQueryClient();

    const [state, dispatch] = useReducer(customReducer<TContributions>, INITIAL_STATE);

    const toastRef=useRef<Toast>(null);

    const contributionsDownloadOP=useRef<OverlayPanel>(null);

    const {data:membersList, isLoading, refetch} = useMembershipListFetch({urlLink: `${getBaseURL()}/membership/get_all_members`});

    const {data:contributionsList, isLoading:contributionLoading, refetch:contributionFetch, dataUpdatedAt} = useContributionsListFetch({urlLink: `${getBaseURL()}/contributions/get_all_contributions`});

    const uploadComponentRef = useRef(null);

    useEffect(() => {
        if(membersList){
            setStateValues({
                members:remakeDropdownSelects(membersList,'fullName','memberId'),
                wageTypes:remakeDropdownSelects([{'id':5005,'desc':'Official Credit Union'}],'desc','id')
            });
        }
    }, []);
    const setStateValues = (stateValues: Partial<TContributions>) => {
        dispatch({
            type: REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES,
            payload: {...stateValues}
        });
    }
    if(isLoading) return <Loader/>;
    if(contributionLoading) return <Loader/>
    const getStateValues=()=>{
        const props:Array<keyof TContributions>=["contributionId","memberId","wageTypeId","amountContributed","contributionDate"];
        return sliceObject(props,state);
    }

    const onContributionDialogShow=()=>{
        if (!state.editingState) {
            setStateValues({
                contributionId: uuidv4()
            });
        }
    }
    const onSelectChange = (e: DropdownChangeEvent) => {
        selectControlChange(e,dispatch);
    }
    const contributionMenu = () => {
        return [
            {
                label: 'New Contribution',
                icon: 'pi pi-plus',
                command: () => setStateValues({showDialog: true})
            },
            {
                label: 'Upload File',
                icon: 'pi pi-upload',
                command: () => setStateValues({showUploaderDialog: true})
            },
            {
                label: 'Contribution List',
                icon: 'pi pi-download',
                command: (event:React.MouseEvent<HTMLButtonElement>) => setStateValues({showContributionsDialog:true})
            },
            {
                label: 'Download File',
                icon: 'pi pi-download',
                command: () => downloadUploadableList()
            },
            {
                label: 'Refresh Table ',
                icon: 'pi pi-refresh',
                command: () => contributionFetch()
            }
        ]
    }
    const validateContributionPage=()=>{
        return pageDataValidation<IContributions>(validateContribution,getStateValues(),toastRef);
    }
    const downloadContributionsList=()=>{
        const startDate=state.contributionDownloadDates[0];

        const endDate=state.contributionDownloadDates[1];

        const cList=contributionsList?.filter((contribution:Partial<TContributions>)=>{
            return isAfter(new Date(contribution.contributionDate as Date),subDays(new Date(startDate),1))
                && isBefore(new Date(contribution.contributionDate as Date),addDays(new Date(endDate),1));
        }).map((contribution:Partial<TContributions>)=>{
            return {
                memberId:contribution.memberId,
                sapNumber:contribution.sapNumber,
                coyNumber:contribution.coyNumber,
                fullName:contribution.fullName,
                wageTypeDesc:contribution.wageTypeDesc,
                contributionDate:format(new Date(contribution.contributionDate!.toString()),'yyyy-MM-dd'),
                amountContributed:contribution.amountContributed
            }
        });

        try{
            downloadExcelFile(!state.downloadMemberOnly?cList:getMemberOnlyContribution(cList as TContributions[]),'contributionsList',[
                {
                    sapNumber:'SAP NUMBER',
                    coyNumber:'COY NUMBER',
                    fullName:'FULL NAME',
                    wageTypeId:'WT CODE',
                    wageTypeDesc:'WAGE TYPE',
                    contributionDate:'DATE',
                    amountContributed:'AMOUNT CONTRIBUTED'
                }
            ]);
        }catch(error:any){
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }
    }
    const getMemberOnlyContribution=(contributionsList:TContributions[])=>{
        return contributionsList.filter((mContribution:Partial<TContributions>)=>mContribution.memberId===state.filteredContributionMemberId);
    }
    const downloadUploadableList=()=>{
        const cList=membersList?.map((contribution:Partial<TContributions>)=>{
            return {
                memberId:contribution.memberId,
                sapNumber:contribution.sapNumber,
                coyNumber:contribution.coyNumber,
                fullName:contribution.fullName,
                wageTypeId:contribution.wageTypeId,
                wageTypeDesc:contribution.wageTypeDesc,
                contributionDate:format(new Date(),'yyyy-MM-dd'),
                amountContributed:0
            }
        })
        try{
            downloadExcelFile(cList,'contribution_upload_list',[
                {
                    memberId:'MEMBER ID',
                    sapNumber:'SAP NUMBER',
                    coyNumber:'COY NUMBER',
                    fullName:'FULL NAME',
                    wageTypeId:'WT',
                    wageTypeDesc:'WAGE TYPE',
                    contributionDate:'DATE',
                    amountContributed:'AMOUNT CONTRIBUTED'
                }
            ]);
        }catch(error:any){
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }
    }
    const promptContributionSave = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!validateContributionPage()) return;
        promptUserAction({yesAction: acceptContribution, event, displayText: 'Are you sure you want to add this contribution?'});
    }
    const promptContributionUpdate = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!validateContributionPage()) return;
        promptUserAction({yesAction: updateContribution, event, displayText: 'Are you sure you want to update selected Contribution?'});
    }
    const acceptContribution=async ()=>{
        try{
            contribution.makeInstance(getStateValues() as IContributions);

            setStateValues({isLoading:true});
            const contributionResponse=await contribution.createInstance();

            if(contributionResponse.data.status===1){

                await addRecordToCache(queryClient,['contributionsList'],contributionResponse.data.operatedData);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Save Success',
                    message: 'New Contribution was successfully saved with the supplied credentials',
                    infoType: 'success',
                    life: 5000
                });
                resetStateValues();
            }
        }catch(error:any){
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }finally {
            setStateValues({isLoading:false});
        }
    }
    const setupContributionEdit=(event: React.MouseEvent<HTMLButtonElement>)=>{

        const clickedContributionId=getTableRowId(event,'id');

        const selectedContribution=contributionsList?.find((contribution:IContributions)=>contribution.contributionId===clickedContributionId);
        if(selectedContribution){
            const {contributionId,memberId,wageTypeId,contributionDate,amountContributed}=selectedContribution;
            setStateValues({
                contributionId,
                memberId,
                wageTypeId,
                contributionDate:new Date(contributionDate.toString()),
                amountContributed,
                editingObjectId:clickedContributionId,
                editingState:true,
                showDialog:true
            });
        }
    }
    const promptContributionDelete=async (event: React.MouseEvent<HTMLButtonElement>)=>{
        try{
            const deletingContributionId=getTableRowId(event,'name');
            setStateValues({isLoading:true});
            const deletedContributionResponse=await contribution.deleteInstance(deletingContributionId);
            if(deletedContributionResponse.data.status===1){
                await deleteCacheRecord<IContributions>(queryClient,['contributionsList'],
                    [deletedContributionResponse.data.operatedData,deletingContributionId,'contributionId']);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Delete Success',
                    message: 'Contribution was successfully deleted!',
                    infoType: 'success',
                    life: 5000
                });
            }
        }catch(error:any){
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }finally {
            setStateValues({isLoading:false});
        }
    }
    const updateContribution=async ()=>{
        try{
            contribution.makeInstance(getStateValues() as IContributions);
            setStateValues({isLoading:false});
            const updateResponse=await contribution.updateInstance();
            if(updateResponse.data.status===1){
                await updateCacheRecord(queryClient,['contributionsList'],
                    [updateResponse.data.operatedData,state.editingObjectId,'contributionId']);
                displayMessage({
                    toastComponent: toastRef,
                    header: 'Update Success',
                    message: 'Selected Contribution was successfully updated with the supplied credentials',
                    infoType: 'success',
                    life: 3000
                });
                resetStateValues()
            }
        }catch(error:any){
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }finally {
            resetStateValues()
        }
    }
    const uploadContributionsFile=async (event:FileUploadHandlerEvent)=>{
        try{
            const file=event.files[0];
            const uploadFileHeaders=['Pers.No.','Last name First name','WT','Wage Type Long Text','Amount'];
            const uploadObjectNameFields=['sapNumber','fullName','wageTypeId','wageTypeDesc','amountContributed'];
            const uploadData=await configureExcelUpload(file,uploadFileHeaders,uploadObjectNameFields);
            const contributionDate=format(new Date(state.contributionDate as Date),'yyyy-MM-dd');

            uploadData.forEach((contribution:TContributions)=>{
               if(contribution.sapNumber===undefined || contribution.fullName===undefined || contribution.wageTypeId===undefined){
                   throw Error('Check Excel File for any unwanted and incomplete record');
               }
            });
            setStateValues({isLoading:true})
            const uploadResponse=await contribution.uploadMemberContributions(uploadData,contributionDate);

            if(uploadResponse.data.status===1){
                await addRecordsToCache(queryClient,['contributionsList'],uploadResponse.data.operatedData);
                displayMessage({
                    header:'Upload Success',
                    message:'Contributions were successfully uploaded',
                    infoType:'success',
                    toastComponent:toastRef,
                    life:5000
                });
                await queryClient.invalidateQueries(['membersList']);//force refetch of members list;
            }
        }catch(error:any){
            displayMessage({
                header:'Error',
                message:error.message,
                infoType:'error',
                toastComponent:toastRef,
                life:5000
            });
        }finally {
            setStateValues({isLoading:false});
        }
    }
    const onCheckBoxChange=(e: CheckboxChangeEvent)=>{
        setStateValues({downloadMemberOnly:e.checked});
    }
    const resetStateValues=()=>{
        setStateValues({
            memberId:'',
            wageTypeId:0,
            amountContributed:'',
            contributionDate:new Date(),
            showDialog:false,
            editingState:false,
            isLoading:false
        });
    }
    return (
        <>
            {state.isLoading && <Loader/>}
            <GeneralPageProps toastRef={toastRef}/>
            <div className="p-fluid lg:pl-5">
                <SimpleTableWithMenu
                    tableKey={"contributionId"}
                    columnsDef={
                        [
                            {field: 'sapNumber', header: 'SAP #'},
                            {field: 'fullName', header: 'Name'},
                            {field: 'wageTypeDesc', header: 'Wage'},
                            {field: 'amountContributed', header: 'Amount'},
                            {body: (rowData:IContributions)=><div>{format(new Date(rowData.contributionDate.toString()),'yyyy-MM-dd')}</div>, header: 'Date'},
                            {body: (rowData: IContributions) => tableEditOption(setupContributionEdit, promptContributionDelete, rowData.contributionId), header: 'Edit'},
                        ]
                    }
                    tableData={contributionsList}
                    menuModel={contributionMenu()}
                    hasMenuList={true}
                    tableTitle="Members Contributions"
                    lastTableUpdate={dataUpdatedAt}
                    searchValues={['sapNumber','coyNumber','fullName']}
                    searchFieldPlaceHolder="Search by Full Name, COY Number, SAP Number"
                />
            </div>
            <Dialog onHide={() => setStateValues({showDialog: false, editingState: false})} visible={state.showDialog}
                    header="Add Member Contribution"
                    position="top-right"
                    onShow={onContributionDialogShow}
                    className="lg:w-5">
                <div className="p-fluid">
                    <div className="grid p-formgrid">
                        <div className="field lg:col-6 md:col-12 col-12">
                            <FilterSelect
                                selectableOptions={state.members}
                                selectedOption={state.memberId}
                                onSelectChange={onSelectChange}
                                elementId="memberId"
                                defaultValue="Members List"/>
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <FilterSelect
                                selectableOptions={state.wageTypes}
                                selectedOption={state.wageTypeId}
                                onSelectChange={onSelectChange}
                                elementId="wageTypeId"
                                defaultValue="Wage Type"/>
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="taxRate">Contribution Amount</label>
                            <InputText type="number"
                                       value={state.amountContributed}
                                       onChange={(e)=>setStateValues({amountContributed:e.target.value!})}
                                       id="amountContributed"
                            />
                        </div>
                        <div className="field lg:col-6 md:col-12 col-12">
                            <label htmlFor="contributionDate">Contribution Date</label>
                            <DatePicker
                                dateValue={state.contributionDate}
                                onDateChange={(e) => setStateValues({contributionDate: e.value!})}
                                labelText="Contribution Date"
                                controlId="contributionDate"
                            />
                        </div>
                        <div className="field lg:col-4 md:col-12 col-12">
                            <Button onClick={!state.editingState?promptContributionSave:promptContributionUpdate}>{!state.editingState?'Accept Contribution':'Update Contribution'}</Button>
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog header="Upload Members Contribution File" onHide={()=>{setStateValues({showUploaderDialog:false})}} visible={state.showUploaderDialog} position="bottom-right">

                <div className="p-fluid">
                    <div className="grid p-formgrid">
                        <div className="field lg:col-12 md:col-12 col-12">
                            <label htmlFor="contributionDate">Contribution Date</label>
                            <DatePicker
                                dateValue={state.contributionDate}
                                onDateChange={(e) => setStateValues({contributionDate: e.value!})}
                                labelText="Contribution Date"
                                controlId="contributionDate"
                            />
                        </div>
                    </div>
                </div>
                <FileUploader onFileUpload={uploadContributionsFile} fileUploadRef={uploadComponentRef} id="membersUploader" acceptableFileTypes=".xlsx,.csv"/>
            </Dialog>
            <Dialog onHide={() => setStateValues({showContributionsDialog: false, editingState: false})} visible={state.showContributionsDialog}
                    header="Download Contributions File"
                    position="top-right"
                    onShow={onContributionDialogShow}
                    className="lg:w-4">
                <div className="p-fluid">
                    <div className="grid p-formgrid">
                        <div className="field lg:col-8 md:col-12">
                            <FilterSelect
                                selectableOptions={state.members}
                                selectedOption={state.filteredContributionMemberId}
                                onSelectChange={onSelectChange}
                                elementId="filteredContributionMemberId"
                                defaultValue="Contribution Members List"
                                showClearIcon={true}
                            />
                        </div>
                        <div className="field lg:col-4 md:col-12">
                            <div className="flex align-items-center mt-5">
                                <Checkbox inputId="downloadAll" name="downloadAll" value="Member Only" onChange={onCheckBoxChange} checked={state.downloadMemberOnly} />
                                <label htmlFor="downloadAll" className="ml-2">Member Only</label>
                            </div>
                        </div>
                        <div className="field lg:col-8 md:col-12 col-12">
                            <label htmlFor="contributionDate">Contribution Date</label>
                            <DatePicker
                                dateValue={state.contributionDownloadDates}
                                onDateChange={(e) => setStateValues({contributionDownloadDates: e.value! as Date[]})}
                                labelText="Contribution Download Date"
                                controlId="contributionDownloadDates"
                                selectionType={"range"}
                            />
                        </div>
                        <div className="field lg:col-8 md:col-12 col-12">
                            <Button onClick={downloadContributionsList}>Download Contributions List</Button>
                        </div>

                    </div>
                </div>

            </Dialog>
        </>
    )
}
export default Contributions;
