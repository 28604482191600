import {AxiosResponse} from "axios";
import {fetchAction, getBaseURL} from "../helpers/utils";
import {IOtherPostings, IReportsInterface, TReportData} from "../helpers/typesUtils";

class Reports{
    async getReportData(reportYear:number): Promise<AxiosResponse<{otherPostings:IOtherPostings[]|string,
        reportData:TReportData|string,reportDataPreviousYear:TReportData|string,
        otherPostingsPreviousYear:IOtherPostings|string,status:number,currentYPreviousYearBF:number,previousYearPreviousYearBF:number}>> {
        return await fetchAction('post', `${getBaseURL()}/other_postings/fetch_report_data`,{reportYear});
    }

    async updateUnionSettings(settingsData:Partial<IReportsInterface>):Promise<AxiosResponse<{status:number,operatedData:IReportsInterface}>>{
        return await fetchAction('post', `${getBaseURL()}/other_postings/update_application_settings`,{settingsData});
    }

    async getUnionSettings():Promise<AxiosResponse<{status:number,operatedData:IReportsInterface}>>{
        return await fetchAction('get', `${getBaseURL()}/other_postings/get_application_settings`,{});
    }
}
export default Reports;
