import {Toast} from "primereact/toast";
import { get, set, del } from "idb-keyval";
import { PersistedClient, Persister } from "@tanstack/react-query-persist-client";
import Joi, {valid} from "joi";
import {DropdownChangeEvent} from "primereact/dropdown";
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import {SheetHeader, TFetchListArg} from "./typesUtils";
import * as XLSX from 'xlsx';
import {Login} from "../pages/Login";
const _=require('lodash');
export interface DropdownOption {
    name: string;
    id: string | number;
    value: string;
}
export const enum REDUCER_ACTION_TYPE {
    CHANGE_STATE_VALUES
}

export type StateReducerAction = {
    type: REDUCER_ACTION_TYPE,
    payload: {}
}
export function sliceObject(selectableProperties: Array<keyof any>, parentObject: any): any {
    const selectedValues:any = {};
    for (const prop of selectableProperties) {
        selectedValues[prop] = parentObject[prop];
    }
    return selectedValues;
}
export const remakeDropdownSelects = (
    data: any[],
    nameOption: string,
    idOption: string|number
): DropdownOption[] => {
    return data.map((selectData) => ({
        name: selectData[nameOption],
        id: selectData[idOption],
        value: selectData[idOption],
    }));
};

interface DMProps{
    toastComponent:React.RefObject<Toast>,
    header:string,
    message:string,
    infoType:"success" | "info" | "warn" | "error" | undefined,
    life:number
}
export const displayMessage = ({header, message, infoType,toastComponent,life}:DMProps) => {
    toastComponent.current?.show({
        severity: infoType,
        summary: header,
        detail: message,
        life
    });
};

export async function addNewItemToCache<T> (existingArray:T[], newItem:T)  {
    return  [...existingArray, newItem];
}

export async function updateCacheItem<T> (updatedItem:T,itemBeforeUpdate:T,allItems:T[]){

    const indexOfItem=_.findIndex(allItems,itemBeforeUpdate);

    const newItems:T[]=[...allItems];

    _.set(newItems,indexOfItem,updatedItem);

    return newItems;
}
export async function deleteCacheItem<T> (itemBeforeDelete:T,allItems:T[]){

    const indexOfItem=_.findIndex(allItems,itemBeforeDelete);

    const clonedItems=[...allItems];

    _.pullAt(clonedItems,indexOfItem);

    return clonedItems;
}

export function createIDBPersister(idbValidKey: IDBValidKey = "membershipData") {
    return {
        persistClient: async (client: PersistedClient) => {
            await set(idbValidKey, client);
        },
        restoreClient: async () => {
            return await get<PersistedClient>(idbValidKey);
        },
        removeClient: async () => {
            await del(idbValidKey);
        },
    } as Persister;
}
export const getTableRowId=(e:React.MouseEvent<HTMLButtonElement>,rowProp:string)=>{
    //@ts-ignore
    if (e.target.closest('button')[rowProp]) {
        //@ts-ignore
        return e.target.closest('button')[rowProp]!;
    }
    return 'Found';
}
export const pageDataValidation=<T>(validationObject:Joi.ObjectSchema<T>,stateValues:Partial<T>,toastRef: React.MutableRefObject<any>):boolean=>{
    const value=validationObject.validate(stateValues,{abortEarly:true});
    if(value.error){
        displayMessage({toastComponent: toastRef, header: 'Error',
            message: value.error.details[0].message, infoType: 'error', life: 3000});
        return false;
    }
    return true;
}
export const changeState = <T>(stateValues: Partial<T>,dispatch: React.Dispatch<StateReducerAction>) => {
    dispatch({
        type: REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES,
        payload: {...stateValues}
    });
}
export const inputChange = (e: React.ChangeEvent<HTMLInputElement>,dispatch:React.Dispatch<StateReducerAction>) => {
    changeState({[e.target.id]: e.target.value},dispatch);
}
export const selectControlChange = (e: DropdownChangeEvent,dispatch: React.Dispatch<StateReducerAction>) => {
    changeState({[e.target.id]: e.value},dispatch);
}


export async function fetchAction<T>(
    actionType: AxiosRequestConfig['method'],
    url: string,
    actionData: object
): Promise<AxiosResponse<T>> {
    const config: AxiosRequestConfig = {
        method: actionType,
        url: url,
        headers: {
            'Content-Type': 'application/json',
        },
        data: actionData
    };

    try {
        const response = await axios(config);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw error;
        } else {
            console.log('An error occurred:', error);
            throw new Error('An error occurred while making the request.');
        }
    }
}
export function customReducer<T> (state:  T, action: StateReducerAction):  T {

    switch (action.type) {
        case REDUCER_ACTION_TYPE.CHANGE_STATE_VALUES:
            return {...state, ...action.payload}
        default:
            throw new Error();
    }
}

export const fetchPageInit = async <T>(reqParams: TFetchListArg): Promise<T> => {

    const response: AxiosResponse<T> = await fetchAction('get',
        `${reqParams.urlLink}`, {});
    console.log(response)

    if (response.status !== 1) {
        throw new Error('Failed to fetch data');
    }

    return response.data;
};




export const configureExcelUpload = async (
    file: File,
    uploadFileHeaders: string[],
    uploadObjectNameFields: string[]
): Promise<any[]> => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.onload = (e) => {
            const bufferArray = e.target?.result as ArrayBuffer;
            const wb = XLSX.read(bufferArray, { type: 'buffer' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const headers:any = XLSX.utils.sheet_to_json(ws, { header: 1 })[0]; // Get excel file headers
            const sheetHeaders=headers.map((header:string)=>header.trim());
            if (!checkExcelDataIntegrity(sheetHeaders, uploadFileHeaders)) {
                reject(new Error('File Dishonesty detected'));
                return;
            }
            const parsedData = XLSX.utils.sheet_to_json(ws, { range: 1, header: uploadObjectNameFields }); // Starting file read from row 2;

            resolve(parsedData);
        };

        fileReader.readAsArrayBuffer(file);
    });
};

export const checkExcelDataIntegrity= (excelFileHeaders: [], localHeaders: string[])=>{

    const validData=excelFileHeaders.every((header:string)=>localHeaders.includes(header));
    let arrangementIsValid=true;
    localHeaders.forEach((uploadHeader,index)=>{
        // @ts-ignore
        const index2=excelFileHeaders.indexOf(uploadHeader.trim());
        if(index!==index2){
            arrangementIsValid=false;
            return false;
        }
    });
    return validData && arrangementIsValid;
}

export function getFileExtension(filename: string): string {
    return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2);
}



export const downloadExcelFile = (jsonData: any[] = [], filename = 'output', sheetHeaders: SheetHeader[] = []) => {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sheetHeaders, { skipHeader: true });
    XLSX.utils.sheet_add_json(ws, jsonData, { skipHeader: true, origin: 'A2' });

    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, ws, filename);

    const workbookOutput: Uint8Array = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
    const blob: Blob = new Blob([workbookOutput], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const downloadLink: HTMLAnchorElement = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = `${filename}.xlsx`;
    downloadLink.click();
};
export const encodeFilesToData = (
    file: File
): Promise<{ fileData: string; fName: string }> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            const base64Data = reader.result as string;
            const fileExtension = file.name.split('.').pop() || '';
            const fName = `file_${Date.now()}.${fileExtension}`;
            resolve({ fileData: base64Data, fName });
        };

        reader.onerror = () => {
            reject(reader.error);
        };

        reader.readAsDataURL(file);
    });
};

export const formatCell=({fontName='Calibri',size=11,underline='single',bold=false,italic=false})=>{
    return { name: fontName, family: 4, size, underline: underline, bold,italic }
}
export const getBaseURL=()=>{
    return `https://gfgcudb.vitalfacilitiesgroup.com`;
    // return `http://localhost:5000`;
}
